import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GardensContext } from "./GardensContext";
import { AppContext } from "components/app_root/AppContext";
import { faChevronRight, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButtonList from "components/common/app_button_list/AppButtonList";
import AppButtonListButton from "components/common/app_button_list/AppButtonListButton";
import Garden from "data/models/Garden";
import { db } from "index";
import { useNavigate } from "react-router-dom";
import AppButton from "components/common/app_button/AppButton";
import Divider from "components/common/divider/Divider";
import NewGardenPanel from "./NewGardenPanel";
import Title from "components/common/title/Title";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import ImportGardenPanel from "./ImportGardenPanel";
import classNames from "classnames";
import sampleGarden from "./sampleGarden";
import GardensMenu from "./GardensMenu";

export default function GardensPage() {
    const { isSmallWindow } = useContext(AppContext);
    const [title, setTitle] = useState<string | null>("Your Gardens");
    const [gardens, setGardens] = useState<Garden[]>([]);
    const [showNewGardenPanel, setShowNewGardenPanel] = useState<boolean>(false);
    const [showGardenImportPanel, setShowGardenImportPanel] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const getGardens = useCallback(async () => {
        const dbGardens = await db.getGardens();
        setGardens(_ => dbGardens || []);
        setLoading(_ => false);
    }, []);

    const noGardens = useMemo(() => {
        return !loading && gardens.length === 0;
    }, [loading, gardens]);

    useEffect(() => {
        if (!db.initialized()) {
            return;
        }

        getGardens();
    }, [db.initialized()]);

    const createGarden = useCallback(() => {
        setShowNewGardenPanel(_ => true);
    }, []);

    const importGarden = useCallback(() => {
        setShowGardenImportPanel(_ => true);
    }, []);

    const copySampleGarden = useCallback(async () => {
        setLoading(_ => true);
        await db.importGarden(sampleGarden());
        getGardens();
    }, []);

    return <GardensContext.Provider
        value={{
            title: title,
            setTitle: setTitle,
        }}>
        <div className="relative mx-auto w-full flex flex-1 overflow-hidden">
            <div className="relative mx-auto w-full flex flex-1 h-dvh overflow-hidden">
                <GardensMenu />
                <div className="min-w-0 flex-1 overflow-auto" style={{ marginTop: isSmallWindow ? '2.5rem' : undefined }}>
                    <div className="p-5 h-full flex flex-col">
                        <div>
                            <Title className="text-2xl mb-2">Your Gardens</Title>
                            <AppButton
                                buttonType="outline"
                                onClick={createGarden}
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                Create Garden
                            </AppButton>
                            <AppButton
                                buttonType="outline"
                                onClick={importGarden}
                                className="ml-2"
                            >
                                <FontAwesomeIcon icon={faUpload} className="mr-1" />
                                Import Garden
                            </AppButton>
                            <Divider className="my-5" />
                        </div>
                        {noGardens && <div className="flex-1 flex flex-col items-center justify-center overflow-auto">
                            <div className="text-center p-4 rounded-md">
                                <h1 className="font-semibold mb-2">You don't have any gardens.</h1>
                                <p className="mb-2">Create or import a garden using the buttons above to get started.</p>
                                <AppButton buttonType="primary" onClick={copySampleGarden}>Or, create a garden from a sample!</AppButton>
                            </div>
                        </div>}
                        {!noGardens && <div className="flex-1 overflow-auto">
                            <AppButtonList>
                                {gardens.map(gdn =>
                                    <AppButtonListButton key={gdn.id}
                                        onClick={() => navigate(`/gardens/` + gdn.id)}>
                                        <div className="flex flex-row">
                                            <div className="flex-1">
                                                <h1 className="mb-1">{gdn.details.name ?? "{Untitled}"}</h1>
                                                <span className="block text-xxs mt-1 opacity-50"><span className="font-semibold">Season:</span> {gdn.details.seasonStart} to {gdn.details.seasonEnd}</span>
                                            </div>
                                            <div className=" self-center">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        </div>
                                    </AppButtonListButton>)}
                            </AppButtonList>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <NewGardenPanel
            show={showNewGardenPanel}
            onClose={() => setShowNewGardenPanel(false)}
            onGardenCreated={_ => { getGardens(); }} />
        <ImportGardenPanel
            show={showGardenImportPanel}
            onClose={() => setShowGardenImportPanel(false)}
            onGardenCreated={_ => { getGardens(); }} />
    </GardensContext.Provider >;
}
