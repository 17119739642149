import { DateTime } from "luxon";

export function timestampToDateStr(timestamp: string) {
  const dt = DateTime.fromISO(timestamp);
  return dt.toISODate();
}

export function dateToTimestamp(date: string) {
  const dt = DateTime.fromISO(date);
  return dt.toUTC().toISO();
}

export function timestampToDateTime(timestamp: string) {
  return DateTime.fromISO(timestamp);
}

export function weeksFromDate(timestamp: string, weeks?: number) {
  if (isNaN(weeks ?? NaN)) {
    weeks = 0;
  }

  const dt = DateTime.fromISO(timestamp);
  return dt.plus({ weeks: weeks });
}

export function epochToTimestamp(epoch: number) {
  const dt = DateTime.fromMillis(epoch);
  return formattedTimestamp(dt.toISO(), 'MMM dd yyyy');
}

export function formattedTimestamp(timestamp: string | null | undefined, format = "MMM dd yyyy h:mm:ss a") {
  const dt = DateTime.fromISO(timestamp ?? "");
  return dt.toFormat(format);
}

export function numToMonth(month: number) {
  const dt = DateTime.fromObject({ month: month });
  return dt.toFormat("MMM");
}