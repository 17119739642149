import classNames from "classnames";

export type AppSelectOption = {
    value: string;
    label: string;
    disabled?: boolean;
}

type AppSelectProps = {
    options?: AppSelectOption[];
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const AppSelect = (props: AppSelectProps) => {
    const { options, ...rest } = props;

    return <select {...rest} className={classNames("transition-colors duration-default block text-sm rounded-md border-0 py-1.5 bg-main-background-secondary focus:bg-main-background-secondary-hover text-gray-900 shadow-sm ring-0 ring-inset placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-gray-300", props.className)}>
        {options?.map((option, index) => <option key={index} value={option.value} disabled={option.disabled ? true : undefined}>{option.label}</option>) || props.children}
    </select>
}

export default AppSelect;