import classNames from "classnames";

const AppCheckbox = (props: { label?: string } & React.InputHTMLAttributes<HTMLInputElement>) => {
    if (props.label) {
        return <div className={classNames("inline-flex flex-row relative items-center", props.className)}>
            <input
                {...props}
                type="checkbox"
                className="transition-colors duration-default rounded-md cursor-pointer border-0 py-1.5 text-gray-900 bg-main-background-secondary shadow-sm ring-0 w-6 h-6 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm"
            />
            <div>
                <label
                    htmlFor={props.id}
                    className="pl-2 text-sm font-medium text-secondary cursor-pointer"
                >
                    {props.label}
                </label>
            </div>
        </div>;
    }

    return <input
        {...props}
        type="checkbox"
        className="transition-colors duration-default rounded-md cursor-pointer border-0 py-1.5 text-gray-900 bg-main-background-secondary shadow-sm ring-0 w-6 h-6 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm"
    />;
}

export default AppCheckbox;