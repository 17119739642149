import { faBars, faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "components/common/app_menu/MenuItem";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "components/app_root/AppContext";
import MenuWrapper from "components/menu_wrapper/MenuWrapper";
import ColorLogo from "components/common/logo/ColorLogo";
import { AppSupportContext } from "./AppSupportContext";
import { faFlower } from "@fortawesome/pro-solid-svg-icons";
import SharingButtons from "components/sharing_buttons/SharingButtons";

const AppMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { isSmallWindow } = useContext(AppContext);
    const { title: menuName } = useContext(AppSupportContext);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [isSmallWindow]);

    const closeMenu = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        setIsMenuOpen(_ => false);
    }, [setIsMenuOpen]);

    if (!isSmallWindow) {
        return <MenuWrapper className="m-5 mr-0">
            <div className="flex flex-row pb-8 items-center justify-center">
                <ColorLogo className="w-8 h-8 mr-2" />
                <div className="cursor-default select-none">
                    <h1 className="font-bold text-xl text-main-background-tertiary">Squardener</h1>
                </div>
            </div>
            <div className=" space-y-4 text-sm flex-1 overflow-auto">
                {/* <MenuItem to="/app/info" selected={menuName === "Hi!"}><FontAwesomeIcon icon={faInfoSquare} className="mr-1 w-4 opacity-secondary text-green-700" /> More information</MenuItem>
                <MenuItem to="/app/help" selected={menuName === "Help"}><FontAwesomeIcon icon={faSquareQuestion} className="mr-1 w-4 opacity-secondary text-blue-900" /> Help</MenuItem>
                <Divider className="my-5" /> */}
                <MenuItem to="/gardens"><FontAwesomeIcon icon={faFlower} className="mr-1 w-4 opacity-secondary text-pink-900" /> Your Gardens</MenuItem>
            </div>
            <div>
                <SharingButtons />
            </div>
        </MenuWrapper>;
    }

    return <>
        <div className="absolute w-full p-2 px-5">
            <div key="title-bar" className="bg-menu-background shadow-xl font-light flex flex-row items-center text-sm rounded-md leading-10 h-10 align-baseline cursor-pointer relative" style={{ zIndex: 9 }} onClick={() => setIsMenuOpen(true)}>
                <ColorLogo className="w-6 h-6 ml-2 block opacity-50" />
                <span className="text-xs opacity-tertiary block flex-1 text-center"><span className="font-semibold">{menuName}</span></span>
                {!isMenuOpen && <button key="open-button" className="absolute text-primary z-10 rounded-e-md right-0 leading-10 w-10 h-10 align-baseline" onClick={() => setIsMenuOpen(true)}>
                    <FontAwesomeIcon icon={faBars} />
                </button>}
            </div>
        </div>
        {isMenuOpen && <>
            <div className="absolute w-full h-full overflow-auto z-20">
                <div className="fixed inset-0 h-full w-full bg-black opacity-50" onClick={() => setIsMenuOpen(false)}></div>
                <MenuWrapper key="open-menu" className="w-full pr-12 flex-1 max-h-dvh">
                    <div className="flex flex-row pb-6 items-center justify-center">
                        <ColorLogo className="w-8 h-8 mr-2" />
                        <div className="cursor-default select-none">
                            <h1 className="font-bold text-xl text-main-background-tertiary">Squardener</h1>
                        </div>
                    </div>
                    <div className=" space-y-4 text-sm flex-1 overflow-auto">
                        {/* <MenuItem to="/app/info" onClick={closeMenu} selected={menuName === "Hi!"}><FontAwesomeIcon icon={faInfoSquare} className="mr-1 w-4 opacity-secondary text-green-700" /> More information</MenuItem>
                        <MenuItem to="/app/help" onClick={closeMenu} selected={menuName === "Help"}><FontAwesomeIcon icon={faSquareQuestion} className="mr-1 w-4 opacity-secondary text-blue-900" /> Help</MenuItem>
                        <Divider className="my-5" /> */}
                        <MenuItem to="/gardens"><FontAwesomeIcon icon={faFlower} className="mr-1 w-4 opacity-secondary text-pink-900" /> Your Gardens</MenuItem>
                        <SharingButtons />
                    </div>
                </MenuWrapper>
                <button key="close-button" className="text-primary fixed z-20 right-2 top-2 leading-10 w-10 h-10 align-baseline rounded-e-md" onClick={() => setIsMenuOpen(false)}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </div>
        </>}
    </>;
}

export default AppMenu;