import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faA, faCalendarAlt, faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import AppButton from "components/common/app_button/AppButton";
import Divider from "components/common/divider/Divider";
import Title from "components/common/title/Title";
import { GardenContext } from "pages/garden/GardenContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { weeksFromDate } from "utilities/Dates";
import { copyObject } from "utilities/Objects";

type SeedNeeds = { plant: string, plantId: string, purchased: boolean, seeds: number, needBy: string, weeks: number }[];
type PlantNeeds = { plant: string, plantId: string, purchased: boolean, quantity: number, needBy: string, weeks: number }[];
type ShoppingNeeds = { plants: PlantNeeds, seeds: SeedNeeds };

const ShoppingPage = () => {
    const ctx = useContext(GardenContext);
    const navigate = useNavigate();
    const [sortSeedsBy, setSortSeedsBy] = useState<"name" | "needBy">("name");
    const [sortPlantsBy, setSortPlantsBy] = useState<"name" | "needBy">("name");
    const shoppingList = ctx.garden!.shopping;

    const shoppingNeeds: ShoppingNeeds = useMemo(() => {
        const needs: ShoppingNeeds = { seeds: [], plants: [] };
        ctx.garden?.plants.forEach(plant => {
            const units = ctx.garden?.units.filter(unit => unit.plantId === plant.id) || [];

            if (units.length === 0) {
                return;
            }

            if (plant.sow === "direct" || plant.sow === "startingIndoors") {
                const seeds = Math.ceil(units.length * plant.seedsPerPlant * plant.plantsPerSq);
                const needBy = weeksFromDate(ctx.garden!.details.seasonStart!, plant.sow === "direct" ? plant.outdoorWeeks : plant.indoorWeeks).toFormat("DDD");
                needs.seeds.push({ plant: plant.name, plantId: plant.id, seeds, needBy, weeks: plant.sow === "direct" ? plant.outdoorWeeks : plant.indoorWeeks, purchased: shoppingList.find(s => s.plantId === plant.id) !== undefined });
            } else {
                const plants = Math.ceil(units.length * plant.plantsPerSq);
                const needBy = weeksFromDate(ctx.garden!.details.seasonStart!, plant.transplantWeeks).toFormat("DDD");
                needs.plants.push({ plant: plant.name, plantId: plant.id, quantity: plants, needBy, weeks: plant.transplantWeeks, purchased: shoppingList.find(s => s.plantId === plant.id) !== undefined });
            }
        });

        return needs;
    }, [shoppingList, ctx.garden?.units]);

    useEffect(() => {
        ctx.setTitle?.("Shopping List");
    }, []);

    const updatePurchased = useCallback((id: string) => {
        const newGarden = copyObject(ctx.garden!);
        const idx = newGarden.shopping.findIndex(s => s.plantId === id);
        if (idx === -1) {
            newGarden.shopping.push({ plantId: id, created: Date.now() });
        } else {
            newGarden.shopping.splice(idx, 1);
        }
        ctx.onGardenUpdated?.(newGarden);
    }, [shoppingList, ctx.garden?.units])

    const hasPlants = !(shoppingNeeds.seeds.length === 0 && shoppingNeeds.plants.length === 0);

    return (
        <div className="flex flex-col h-full w-full">
            <Title className="text-2xl mb-2">Seed & Plant Shopping List</Title>

            {!hasPlants && <div className="flex flex-col items-center justify-center pt-8">
                <div className="text-center p-4 rounded-md">
                    <h1 className="font-semibold mb-2">You don't have any planted plants, yet!</h1>
                    <p className="mb-2">Create some plants and put them in your garden from the Garden Planner.</p>
                    <AppButton buttonType="primary" onClick={() => navigate("../planner")}>Get planning now!</AppButton>
                </div>
            </div>}

            {hasPlants && <div className="p-4 flex-1 rounded-md overflow-hidden bg-white">
                <div className="overflow-auto w-full h-full">
                    {shoppingNeeds.seeds.length > 0 && <>
                        <h1 className="font-bold text-lg">Seeds</h1>
                        <div className="text-xxs py-1">
                            <div className="inline-flex flex-row w-[18rem]">
                                <button
                                    type="button"
                                    onClick={() => setSortSeedsBy("name")}
                                    className={classNames("flex-1 inline-block text-center cursor-pointer select-none transition-colors duration-default rounded-md py-1 px-1 text-xs font-semibold disabled:opacity-20 rounded-e-none border border-main-background-tertiary text-green-800 bg-transparent  hover:bg-button-outline-bg", sortSeedsBy === "name" && "!text-white !bg-main-background-tertiary")}>
                                    <FontAwesomeIcon icon={faA} className="mr-1" /> Sort by name
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setSortSeedsBy("needBy")}
                                    className={classNames("flex-1 inline-block text-center cursor-pointer select-none transition-colors duration-default rounded-md py-1 px-1 text-xs font-semibold disabled:opacity-20 rounded-l-none border border-main-background-tertiary text-green-800 bg-transparent  hover:bg-button-outline-bg", sortSeedsBy === "needBy" && "!text-white !bg-main-background-tertiary")}>
                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" /> Sort by needed by
                                </button>
                            </div>
                        </div>
                        {shoppingNeeds.seeds.filter(s => !s.purchased).sort((a, b) => sortSeedsBy === "name" ? a.plant.localeCompare(b.plant) : a.weeks - b.weeks).map((need, idx) => <button type="button" key={idx} className="block w-full text-left transition hover:bg-main-background-secondary cursor-pointer p-2 rounded-md" onClick={() => updatePurchased(need.plantId)}>
                            <div className="flex items-center">
                                <div className="text-xl text-slate-400"><FontAwesomeIcon icon={faSquare} /></div>
                                <div className="px-2 font-bold">{need.plant}</div>
                                <div className="opacity-70 font-normal">{need.seeds} seeds</div>
                            </div>
                            <div className="opacity-60 pl-7 text-xxs">Needed by {need.needBy}</div>
                        </button>)}
                        {shoppingNeeds.seeds.filter(s => s.purchased).sort((a, b) => sortSeedsBy === "name" ? a.plant.localeCompare(b.plant) : a.weeks - b.weeks).map((need, idx) => <button type="button" key={idx} className="block w-full text-left transition hover:bg-main-background-secondary cursor-pointer p-2 rounded-md text-green-700 opacity-70" onClick={() => updatePurchased(need.plantId)}>
                            <div className="flex items-center ">
                                <div className="text-xl"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                <div className="px-2 font-bold">{need.plant}</div>
                                <div className="opacity-70">{need.seeds} seeds</div>
                            </div>
                            <div className="opacity-60 pl-7 text-xxs">Needed by {need.needBy}</div>
                        </button>)}
                    </>}
                    {shoppingNeeds.seeds.length > 0 && shoppingNeeds.plants.length > 0 && <Divider className="my-5" />}
                    {shoppingNeeds.plants.length > 0 && <>
                        <h1 className={classNames("font-bold text-lg", shoppingNeeds.plants.length > 0 ? "mt-2" : "")} > Plants</h1>
                        <div className="text-xxs py-1">
                            <div className="inline-flex flex-row w-[18rem]">
                                <button
                                    type="button"
                                    onClick={() => setSortPlantsBy("name")}
                                    className={classNames("flex-1 inline-block text-center cursor-pointer select-none transition-colors duration-default rounded-md py-1 px-1 text-xs font-semibold disabled:opacity-20 rounded-e-none border border-main-background-tertiary text-green-800 bg-transparent  hover:bg-button-outline-bg", sortPlantsBy === "name" && "!text-white !bg-main-background-tertiary")}>
                                    <FontAwesomeIcon icon={faA} className="mr-1" /> Sort by name
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setSortPlantsBy("needBy")}
                                    className={classNames("flex-1 inline-block text-center cursor-pointer select-none transition-colors duration-default rounded-md py-1 px-1 text-xs font-semibold disabled:opacity-20 rounded-l-none border border-main-background-tertiary text-green-800 bg-transparent  hover:bg-button-outline-bg", sortPlantsBy === "needBy" && "!text-white !bg-main-background-tertiary")}>
                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" /> Sort by needed by
                                </button>
                            </div>
                        </div>
                        {shoppingNeeds.plants.length > 0 && <>
                            {shoppingNeeds.plants.filter(s => !s.purchased).sort((a, b) => sortPlantsBy === "name" ? a.plant.localeCompare(b.plant) : a.weeks - b.weeks).map((need, idx) => <button type="button" key={idx} className="block w-full text-left transition hover:bg-main-background-secondary cursor-pointer p-2 rounded-md" onClick={() => updatePurchased(need.plantId)}>
                                <div className="flex items-center">
                                    <div className="text-xl text-slate-400"><FontAwesomeIcon icon={faSquare} /></div>
                                    <div className="px-2 font-bold">{need.plant}</div>
                                    <div className="opacity-70">{need.quantity} plants</div>
                                </div>
                                <div className="opacity-60 pl-7 text-xxs">Needed by {need.needBy}</div>
                            </button>)}
                            {shoppingNeeds.plants.filter(s => s.purchased).sort((a, b) => sortPlantsBy === "name" ? a.plant.localeCompare(b.plant) : a.weeks - b.weeks).map((need, idx) => <button type="button" key={idx} className="block w-full text-left transition hover:bg-main-background-secondary cursor-pointer p-2 rounded-md text-green-700 opacity-70" onClick={() => updatePurchased(need.plantId)}>
                                <div className="flex items-center">
                                    <div className="text-xl"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="px-2 font-bold">{need.plant}</div>
                                    <div className="opacity-70">{need.quantity} plants</div>
                                </div>
                                <div className="opacity-60 pl-7 text-xxs">Needed by {need.needBy}</div>
                            </button>)}
                        </>}
                    </>}
                </div>
            </div>}
        </div>
    );
};

export default ShoppingPage;