import { createContext } from "react";

export type AppSupportContextType = {
  title: string | null;
  setTitle: ((title: string) => void) | undefined;
};
export const AppSupportContext = createContext<AppSupportContextType>({
  title: "",
  setTitle: undefined,
});
