import Garden from "data/models/Garden";

class Transfer {
    public static createGardenDownload(garden: Garden) {
        const gardenData = JSON.stringify(garden);
        const file = new File([gardenData], `garden-${garden.id}.json`, { type: "application/json" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(file);
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    public static async importGarden(file: File) {
        const fileData = await file.text();
        const garden = JSON.parse(fileData) as Garden;
        return garden;
    }
}

export default Transfer;
