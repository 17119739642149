import classNames from "classnames";

export type ButtonType = 'normal' | 'primary' | 'danger' | 'outline';

const AppButton = (props: {
    buttonType?: ButtonType
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const { buttonType, ...rest } = props;
    const type = buttonType ?? 'normal';

    return <button
        {...rest}
        type="button"
        className={classNames(
            "transition-colors duration-default rounded-md py-2 px-3 text-sm font-semibold text-button-fg shadow-sm ring-1 ring-inset disabled:opacity-20",
            type === "outline" ? "bg-transparent ring-main-background-tertiary text-main-background-tertiary hover:bg-button-outline-bg" : null,
            type === "normal" ? "bg-gray-500 ring-gray-500 hover:bg-gray-700" : null,
            type === "primary" ? "bg-button-primary-bg hover:bg-button-primary-bg-hover" : null,
            type === "danger" ? "bg-red-600 ring-red-600 hover:bg-red-700" : null,
            props.className)}
    >
        {props.children}
    </button>;
}

export default AppButton;