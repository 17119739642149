import Divider from "components/common/divider/Divider";
import SlidingPanel, {
    PanelAction,
} from "components/sliding_panel/SlidingPanel";
import Garden from "data/models/Garden";
import { db } from "index";
import { useCallback, useEffect, useState } from "react";
import { dateToTimestamp, timestampToDateStr, timestampToDateTime } from "utilities/Dates";

type NewGardenPanelProps = {
    show: boolean;
    onClose: () => void;
    onGardenCreated: (garden: Garden) => void;
};

export default function NewGardenPanel(props: NewGardenPanelProps) {
    const [rows, setRows] = useState(2);
    const [columns, setColumns] = useState(2);
    const [name, setName] = useState("New garden");
    const [seasonStart, setSeasonStart] = useState<string | null>(null);
    const [seasonEnd, setSeasonEnd] = useState<string | null>(null);
    const [invalidDates, setInvalidDates] = useState(false);
    const [invalidSizes, setInvalidSizes] = useState(false);

    const createGarden = useCallback(async () => {
        const garden = await db.createGarden({
            name,
            rows,
            columns,
            seasonStart: timestampToDateStr(seasonStart ?? "") ?? "",
            seasonEnd: timestampToDateStr(seasonEnd ?? "") ?? "",
        });
        props.onGardenCreated(garden);
        props.onClose();
    }, [props.onClose, props.onGardenCreated, rows, columns, name, seasonStart, seasonEnd]);

    useEffect(() => {
        if (props.show) {
            setRows(2);
            setColumns(2);
            setName("New garden");
            setSeasonStart(null);
            setSeasonEnd(null);
        }
    }, [props.show]);

    useEffect(() => {
        if (seasonStart !== null && seasonEnd !== null) {
            setInvalidDates(
                timestampToDateTime(seasonStart!) >= timestampToDateTime(seasonEnd!)
            );
        }
        else {
            setInvalidDates(true);
        }
    }, [seasonStart, seasonEnd]);

    useEffect(() => {
        if (rows === undefined || columns === undefined) {
            setInvalidSizes(_ => true);
            return;
        }

        if (isNaN(rows) || isNaN(columns)) {
            setInvalidSizes(_ => true);
            return;
        }

        if (rows < 2 || rows > 20 || columns < 2 || columns > 20) {
            setInvalidSizes(_ => true);
            return;
        }

        setInvalidSizes(_ => false);
    }, [rows, columns]);

    const handleAction = (action: PanelAction) => {
        if (action.id === "cancel") {
            props.onClose();
        } else if (action.id === "save") {
            createGarden();
        }
    };

    const updateSeasonStart = (date: string) => {
        setSeasonStart(dateToTimestamp(date));
    };

    const updateSeasonEnd = (date: string) => {
        setSeasonEnd(dateToTimestamp(date));
    };

    return (
        <SlidingPanel
            show={props.show}
            onClose={props.onClose}
            title="Create a new garden"
            subtitle="Provide some basic information about your garden."
            actions={[
                { id: "cancel", text: "Cancel" },
                { id: "save", text: "Save", primary: true, disabled: invalidDates || invalidSizes },
            ]}
            onAction={handleAction}
        >
            <div className="px-4">
                <div className="space-y-6 pt-6">
                    <div>
                        <label
                            htmlFor="garden-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Garden name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="garden-name"
                                id="garden-name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
                <Divider className="my-5" />
                <div>
                    <h1 className="mb-1 font-semibold text-lg">Garden Size</h1>
                    <div>
                        <label
                            htmlFor="rows"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Rows
                            <p className=" text-xxs opacity-50">Min: 2, Max: 20</p>
                        </label>
                        <div className="mt-2">
                            <input
                                name="rows"
                                id="rows"
                                type="number"
                                value={isNaN(rows) ? "" : rows}
                                onChange={(e) => setRows(e.target.valueAsNumber)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <label
                            htmlFor="columns"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Columns
                            <p className=" text-xxs opacity-50">Min: 2, Max: 20</p>
                        </label>
                        <div className="mt-2">
                            <input
                                name="columns"
                                id="columns"
                                type="number"
                                value={isNaN(columns) ? "" : columns}
                                onChange={(e) => setColumns(e.target.valueAsNumber)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    {invalidSizes && (
                        <div className=" rounded-md mt-2 bg-red-100 p-4 text-sm text-red-900">
                            You must have at least 2 and at most 20 rows or columns.
                        </div>
                    )}
                </div>
                <Divider className="my-5" />
                <div>
                    <h1 className="mb-1 font-semibold text-lg">Growing Season</h1>
                    <div>
                        <label
                            htmlFor="season-start"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Start of growing season
                        </label>
                        <div className="mt-2">
                            <input
                                name="season-start"
                                id="season-start"
                                type="date"
                                value={timestampToDateStr(seasonStart ?? "") ?? ""}
                                onChange={(e) => updateSeasonStart(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <label
                            htmlFor="season-end"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            End of growing season
                        </label>
                        <div className="mt-2">
                            <input
                                name="season-end"
                                id="season-end"
                                type="date"
                                value={timestampToDateStr(seasonEnd ?? "") ?? ""}
                                onChange={(e) => updateSeasonEnd(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    {invalidDates && (
                        <div className=" rounded-md mt-2 bg-red-100 p-4 text-sm text-red-900">
                            The season end date must be after the season start date.
                        </div>
                    )}
                </div>
            </div>
        </SlidingPanel>
    );
}
