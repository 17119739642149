import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LocalDb from './data/local/LocalDb';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Root from 'components/root/Root';
import Services from 'services/Services';
import RootError from 'components/root_error/RootError';
import GardensPage from 'pages/gardens/GardensPage';
import GardenPage from 'pages/garden/GardenPage';
import PlannerPage from 'pages/garden/sub_pages/planner/PlannerPage';
import SchedulePage from 'pages/garden/sub_pages/schedule/SchedulePage';
import ShoppingPage from 'pages/garden/sub_pages/shopping/ShoppingPage';
import PlantsPage from 'pages/garden/sub_pages/plants/PlantsPage';
import AppWrapper from 'components/app_wrapper/AppWrapper';
import AppPage from 'pages/app/AppPage';
import { MoreInfoPage } from 'pages/app/sub_pages/more_info/MoreInfoPage';
import { HelpPage } from 'pages/app/sub_pages/help/HelpPage';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const db = new LocalDb();
db.initialize().then(() => {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<Root />}
        errorElement={
          <Root>
            <RootError />
          </Root>
        }
      >
        <Route index element={<GardensPage />} />
        <Route path="gardens" element={<GardensPage />} />
        <Route path="gardens/:id" element={<GardenPage />}>
          <Route index element={<PlannerPage />} />
          <Route path="planner" element={<PlannerPage />} />
          <Route path="plants" element={<PlantsPage />} />
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="shopping" element={<ShoppingPage />} />
        </Route>
        <Route path="app" element={<AppPage />}>
          <Route index element={<MoreInfoPage />} />
          <Route path="info" element={<MoreInfoPage />} />
          <Route path="help" element={<HelpPage />} />
        </Route>
      </Route>
    ),
    { basename: "/" }
  );


  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  Services.initialize();

  root.render(
    <React.StrictMode>
      <AppWrapper>
        <RouterProvider router={router} />
      </AppWrapper>
    </React.StrictMode>
  );
});

serviceWorkerRegistration.register();