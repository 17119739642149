import SowingMethod from "data/models/SowingMethod";
import { PlantingUnit } from "./SchedulePage";
import { GardenContext } from "pages/garden/GardenContext";
import React, { useCallback, useContext, useMemo } from "react";
import { plantsPerSqLabel } from "components/common/plant_per_sqft_input/PlantsPerSqFtUtilities";
import { faSquare } from "@fortawesome/pro-duotone-svg-icons";
import { faSquare as faSquareForCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagSeedling,
  faCheckSquare,
  faMap,
  faSeedling,
} from "@fortawesome/pro-solid-svg-icons";
import { copyObject } from "utilities/Objects";
import classNames from "classnames";
import PlantableUnit from "data/models/PlantableUnit";
import { ScheduleContext } from "./ScheduleContext";

const PlantItem = (props: {
  planting: PlantingUnit;
  units: PlantableUnit[];
  type: SowingMethod;
  updatePlanted: (id: string) => void;
  planted: boolean;
}) => {
  const ctx = useContext(ScheduleContext);
  const units = props.units.filter(
    (u) => u.plantId === props.planting.plant.id
  ).length;
  const plantsNeeded = Math.ceil(units * props.planting.plant.plantsPerSq);
  const seedsNeeded = plantsNeeded * props.planting.plant.seedsPerPlant;
  const { planted } = props;
  const openPlantMap = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      ctx.setPlantIdForMap?.(props.planting.plant.id);
      ctx.setShowMap?.(true);
    },
    [props.planting, props.units, props.type]
  );

  return (
    <li key={props.planting.plant.id} className="py-1">
      <button
        type="button"
        onClick={() => props.updatePlanted(props.planting.plant.id)}
        className={classNames(
          "block w-full text-left transition hover:bg-main-background-secondary cursor-pointer p-2 rounded-md",
          planted && "text-green-700 opacity-60"
        )}
      >
        <div className="flex items-center">
          <div className={classNames("text-xl", !planted && "text-slate-400")}>
            <FontAwesomeIcon
              icon={planted ? faCheckSquare : faSquareForCheck}
            />
          </div>
          <div className="ml-2 flex-1">
            <p className="font-semibold">{props.planting.plant.name}</p>
            <div className="text-xs space-y-1">
              <p className="font-semibold text-orange-800">
                <FontAwesomeIcon
                  icon={faSquare}
                  className="mr-2 inline-block text-orange-700 opacity-70"
                />
                {units} unit{units === 1 ? "" : "s"}
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faSeedling}
                  className="mr-2 text-green-600 opacity-70"
                />
                <span className="font-semibold text-green-800">
                  {plantsNeeded} planting{plantsNeeded === 1 ? "" : "s"}
                </span>
                , at {plantsPerSqLabel(props.planting.plant.plantsPerSq)}
              </p>
              {props.type !== "transplanting" && (
                <p>
                  <FontAwesomeIcon
                    icon={faBagSeedling}
                    className="mr-2 text-blue-600 opacity-70"
                  />
                  <span className="font-semibold text-blue-800">
                    {seedsNeeded} seed{seedsNeeded === 1 ? "" : "s"}
                  </span>{" "}
                  <span>
                    needed, at {props.planting.plant.seedsPerPlant} seed
                    {props.planting.plant.seedsPerPlant === 1 ? "" : "s"} per
                    plant
                  </span>
                </p>
              )}
            </div>
          </div>
          <div
            tabIndex={0}
            title="Show a map of where this plant is planted"
            className="p-4 rounded-md text-xl hover:bg-main-background-secondary"
            onClick={openPlantMap}
          >
            <FontAwesomeIcon
              icon={faMap}
              className=" text-button-primary-bg opacity-20"
            />
          </div>
        </div>
      </button>
    </li>
  );
};

export default function PlantingBlock(props: {
  type: SowingMethod;
  plants: PlantingUnit[];
}) {
  const ctx = useContext(GardenContext);

  const updatePlanted = useCallback(
    (id: string) => {
      const newGarden = copyObject(ctx.garden!);
      const idx = newGarden.plantings.findIndex(
        (s) => s.plantId === id && s.plantingType === props.type
      );
      if (idx === -1) {
        newGarden.plantings.push({
          plantId: id,
          created: Date.now(),
          plantingType: props.type,
        });
      } else {
        newGarden.plantings.splice(idx, 1);
      }
      ctx.onGardenUpdated?.(newGarden);
    },
    [
      props.plants,
      ctx.garden?.units,
      ctx.garden?.plantings,
      ctx.onGardenUpdated,
    ]
  );

  const plantedPlants = useMemo(() => {
    return props.plants.filter((p) =>
      ctx.garden?.plantings.find(
        (s) => s.plantId === p.plant.id && s.plantingType === props.type
      )
    );
  }, [ctx.garden?.plantings, props.plants]);

  const unplantedPlants = useMemo(() => {
    return props.plants.filter(
      (p) =>
        !ctx.garden?.plantings.find(
          (s) => s.plantId === p.plant.id && s.plantingType === props.type
        )
    );
  }, [ctx.garden?.plantings, props.plants]);

  return (
    <div className="mb-4">
      <h2 className="sticky top-10 z-[5] text-sm border-b border-slate-100 pb-1 text-slate-600 bg-white py-1 ml-1">
        {props.type === "direct" && "Planting seeds outdoors"}
        {props.type === "startingIndoors" && "Planting seeds indoors"}
        {props.type === "transplanting" && "Transplanting plants outdoors"}
      </h2>
      <ul className="ml-2 mt-1">
        {unplantedPlants.map((p) => {
          return (
            <PlantItem
              key={p.plant.id + "-unplanted"}
              units={ctx.garden!.units}
              planting={p}
              updatePlanted={updatePlanted}
              planted={false}
              type={props.type}
            />
          );
        })}
        {plantedPlants.map((p) => {
          return (
            <PlantItem
              key={p.plant.id + "-planted"}
              units={ctx.garden!.units}
              planting={p}
              updatePlanted={updatePlanted}
              planted={true}
              type={props.type}
            />
          );
        })}
      </ul>
    </div>
  );
}
