import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "components/common/app_button/AppButton";
import { useNavigate, useRouteError } from "react-router-dom";

export default function RootError() {
  const error = useRouteError() as any;
  const navigate = useNavigate();

  return (
    <div className="relative mx-auto w-full flex flex-1 overflow-auto p-8 pt-16">
      <div className="flex-1 flex flex-col items-center">
        <h1 className="text-3xl text-center font-bold mb-2">Something went wrong!</h1>
        <div className="text-sm text-center">Sorry about that. It's not you, promise.</div>
        <AppButton
          buttonType="primary"
          onClick={() => navigate('/gardens')}
          className="my-4"><FontAwesomeIcon icon={faArrowLeft} className="mr-2" />Go back to Gardens list</AppButton>
        {(error?.stack || error?.message) && <>
          <p className="font-bold text-xs mt-8 mb-2 opacity-50">Error details:</p>
          {error?.message && <pre className=" font-bold whitespace-pre-wrap break-words text-xxs mb-2 opacity-50">{error.message}</pre>}
          {error?.stack && <pre className=" whitespace-pre-wrap break-words text-xxs mb-8 pb-8 opacity-50">{error.stack}</pre>}
        </>}
      </div>
    </div>
  );
}
