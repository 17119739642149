import Divider from "components/common/divider/Divider";
import SlidingPanel, {
    PanelAction,
} from "components/sliding_panel/SlidingPanel";
import Garden from "data/models/Garden";
import Validator from "data/validation/Validator";
import { db } from "index";
import { useCallback, useEffect, useState } from "react";

type ImportGardenPanelProps = {
    show: boolean;
    onClose: () => void;
    onGardenCreated: (garden: Garden) => void;
};

export default function ImportGardenPanel(props: ImportGardenPanelProps) {
    const [importedFile, setImportedFile] = useState<File | null>(null);
    const [gardenData, setGardenData] = useState<any | null>(null);
    const [garden, setGarden] = useState<Garden | null | undefined>(null);
    const [errors, setErrors] = useState<string[] | null | undefined>(undefined);

    const createGarden = useCallback(async () => {
        if (!garden) {
            return;
        }

        await db.importGarden(garden);
        props.onGardenCreated(garden);
        props.onClose();
    }, [props.onClose, props.onGardenCreated, garden]);

    useEffect(() => {
        setImportedFile(null);
        setGarden(null);
        setGardenData(null);
        setErrors(null);
    }, [props.show]);

    useEffect(() => {
        if (!gardenData) {
            return;
        }

        let validatedGarden = Validator.validateGardenData(gardenData);
        setGarden(_ => validatedGarden.garden);
        setErrors(_ => validatedGarden.errors);
    }, [gardenData]);

    useEffect(() => {
        if (!importedFile) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = JSON.parse(e.target?.result as string);
            setGardenData(data);
        };
        reader.readAsText(importedFile);
    }, [importedFile]);

    const handleAction = (action: PanelAction) => {
        if (action.id === "cancel") {
            props.onClose();
        } else if (action.id === "save") {
            createGarden();
        }
    };

    return (
        <SlidingPanel
            show={props.show}
            onClose={props.onClose}
            title="Import a garden"
            subtitle="Upload a garden export to add to your gardens."
            actions={[
                { id: "cancel", text: "Cancel" },
                { id: "save", text: "Save", primary: true, disabled: !garden },
            ]}
            onAction={handleAction}
        >
            <div className="px-4">
                <div className="pt-6">
                    <div>
                        <label
                            htmlFor="import-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Import Garden Data
                        </label>
                        <div className="mt-2">
                            <input
                                name="import"
                                id="import"
                                type="file"
                                onChange={(e) => setImportedFile(e.target.files?.[0] ?? null)}
                                accept=".json"
                                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                {((importedFile && !gardenData) || (gardenData && !garden)) && <>
                    <Divider className="my-5" />
                    <h1 className="mb-1 font-semibold text-lg">Import Results</h1>
                    <div className=" rounded-md mt-2 bg-red-100 p-4 text-sm text-red-900">
                        <p>This file does not contain a valid garden. Please check the file, export and re-import the garden, or try importing again.</p>
                        <div className="mt-2">
                            <p className="font-bold">Errors:</p>
                            <ul className="list-disc mt-2 ml-6">
                                {errors?.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </>}

                {gardenData && garden && <>
                    <Divider className="my-5" />
                    <h1 className="mb-1 font-semibold text-lg">Import Results</h1>
                    <div className=" rounded-md mt-2 bg-green-100 p-4 text-sm text-green-900">
                        This garden was successfully loaded. Click save to add it to your gardens.
                    </div>
                </>}

                <div className=" space-y-2 text-sm text-secondary mt-4">
                    <p>Use this to import a garden that you exported or was given to you.</p>
                    <p>A garden can be exported from the Garden Settings panel while editing your garden.</p>
                    <p>The exported garden is usually a file named something like: garden-5c4aa56a-f7d1-4047-8c93-416a46a45b76.json</p>
                </div>
            </div>
        </SlidingPanel>
    );
}
