export const allowedBelowOneValues = [
    { value: 0.1, label: '1 plant per 10 square units' },
    { value: 0.11, label: '1 plant per 9 square units' },
    { value: 0.125, label: '1 plant per 8 square units' },
    { value: 0.14, label: '1 plant per 7 square units' },
    { value: 0.166, label: '1 plant per 6 square units' },
    { value: 0.2, label: '1 plant per 5 square units' },
    { value: 0.25, label: '1 plant per 4 square units' },
    { value: 0.33, label: '1 plant per 3 square units' },
    { value: 0.5, label: '1 plant per 2 square units' },
];

export function plantsPerSqLabel(plantsPerSq: number) {
    if (plantsPerSq >= 1) {
        return `${plantsPerSq} plant${plantsPerSq === 1 ? "" : "s"} per square unit`;
    } else {
        return allowedBelowOneValues.find(v => v.value === plantsPerSq)?.label;
    }
}