import PlantingBlock from "./PlantingBlock";
import { PlantingDate } from "./SchedulePage";

export default function ScheduleBlock(props: { plantingDate: PlantingDate }) {
    const plants = props.plantingDate.plants;

    const directSow = plants.filter(p => p.type === "direct").sort((a, b) => a.plant.name.localeCompare(b.plant.name));
    const indoorStart = plants.filter(p => p.type === "startingIndoors").sort((a, b) => a.plant.name.localeCompare(b.plant.name));
    const transplant = plants.filter(p => p.type === "transplanting").sort((a, b) => a.plant.name.localeCompare(b.plant.name));

    return <div className="mb-6">
        <h1 className="block sticky top-0 z-10 p-2 rounded-md font-bold bg-[#eef4f1]">{props.plantingDate.date}</h1>
        {directSow.length > 0 && <PlantingBlock type="direct" plants={directSow} />}
        {indoorStart.length > 0 && <PlantingBlock type="startingIndoors" plants={indoorStart} />}
        {transplant.length > 0 && <PlantingBlock type="transplanting" plants={transplant} />}
    </div>
}