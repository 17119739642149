import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { EditMode } from "./utilities/Types";
import GardenPlant from "data/models/GardenPlant";
import PlantSelectorPanel from "./PlantSelectorPanel";
import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";

type PlantSelectorMenuProps = {
  currentPlant: GardenPlant | undefined | null;
  plants: GardenPlant[];
  showingNewPlantModal: boolean;
  onModeChanged: (mode: EditMode) => void;
  onCurrentPlantChanged: (plant: GardenPlant | undefined | null) => void;
  onShowNewPlantModal: (show: boolean) => void;
}

export default function PlantSelectorMenu({
  currentPlant,
  plants,
  onModeChanged,
  onCurrentPlantChanged,
  onShowNewPlantModal,
  showingNewPlantModal,
}: PlantSelectorMenuProps) {
  const [showPlantSelectorPanel, setShowPlantSelectorPanel] = useState(false);

  const increaseValue = useCallback(() => {
    let sortedPlants = plants.sort((a, b) => a.name.localeCompare(b.name));
    if (currentPlant === null) {
      if (sortedPlants.length > 0) {
        onCurrentPlantChanged(sortedPlants[0]);
        onModeChanged("planting");
      } else {
        onCurrentPlantChanged(undefined);
        onModeChanged("shaping");
      }
    } else if (currentPlant === undefined) {
      onCurrentPlantChanged(null);
      onModeChanged("shaping");
    } else {
      let index = sortedPlants.findIndex((p) => p.id === currentPlant?.id);
      if (index === sortedPlants.length - 1) {
        onCurrentPlantChanged(undefined);
        onModeChanged("shaping");
      } else {
        onCurrentPlantChanged(sortedPlants[index + 1]);
      }
    }
  }, [plants, currentPlant]);

  const decreaseValue = useCallback(() => {
    let sortedPlants = plants.sort((a, b) => a.name.localeCompare(b.name));
    if (currentPlant === null) {
      onCurrentPlantChanged(undefined);
      onModeChanged("shaping");
    } else if (currentPlant === undefined) {
      if (sortedPlants.length > 0) {
        onCurrentPlantChanged(sortedPlants[sortedPlants.length - 1]);
        onModeChanged("planting");
      } else {
        onCurrentPlantChanged(null);
        onModeChanged("shaping");
      }
    } else {
      let index = sortedPlants.findIndex((p) => p.id === currentPlant?.id);
      if (index === 0) {
        onCurrentPlantChanged(null);
        onModeChanged("shaping");
      } else {
        onCurrentPlantChanged(sortedPlants[index - 1]);
      }
    }
  }, [plants, currentPlant]);

  return <>
    <div className="relative inline-flex h-[38px]  cursor-pointer items-center rounded-md border border-main-background-tertiary mr-2 text-main-background-tertiary overflow-hidden">
      <button
        type="button"
        onClick={decreaseValue}
        className="px-3 h-[38px] flex items-center bg-main-background-secondary bg-opacity-10 border-r border-main-background-tertiary hover:bg-opacity-15 hover:bg-main-background-tertiary">
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button
        type="button"
        onClick={() => setShowPlantSelectorPanel(true)}
        className="text-left w-[153px] relative inline-flex flex-1 items-center gap-x-1 py-2 px-3 text-sm font-semibold hover:bg-opacity-15 hover:bg-main-background-tertiary">
        {currentPlant !== undefined ? (
          <>
            <div
              className="w-[6.25rem] truncate"
              title={currentPlant?.name ?? "Soil"}
            >
              {currentPlant?.name ?? "Soil"}
            </div>
            <div className="inline-flex flex-1 border-l-[1px] border-l-[#00000030]">
              <div className="relative">
                <ChevronDownIcon
                  className="ml-2 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="relative inline-flex text-left">
              <div className="w-[6.55rem] text-xs leading-5">Pick soil or plant</div>
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5"
                aria-hidden="true"
              />
            </div>
          </>
        )}
      </button>
      <button
        type="button"
        onClick={increaseValue}
        className="px-3 h-[38px] flex items-center bg-main-background-secondary border-l border-main-background-tertiary hover:bg-opacity-15 hover:bg-main-background-tertiary">
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
    <PlantSelectorPanel
      show={showPlantSelectorPanel && !showingNewPlantModal}
      plants={plants}
      onClose={() => setShowPlantSelectorPanel(false)}
      onCurrentPlantChanged={onCurrentPlantChanged}
      onModeChanged={onModeChanged}
      onShowNewPlantModal={onShowNewPlantModal} />
  </>;
}
