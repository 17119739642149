import { useContext, useState } from "react";
import { AppSupportContext } from "./AppSupportContext";
import { AppContext } from "components/app_root/AppContext";
import { Outlet } from "react-router-dom";
import AppMenu from "./AppMenu";

export default function AppPage() {
    const { isSmallWindow } = useContext(AppContext);
    const [title, setTitle] = useState<string | null>("More information");

    return <AppSupportContext.Provider
        value={{
            title: title,
            setTitle: setTitle,
        }}>
        <div className="relative mx-auto w-full flex flex-1 overflow-hidden">
            <><div className="relative mx-auto w-full flex flex-1 h-dvh overflow-hidden">
                <AppMenu />
                <div className="min-w-0 flex-1 overflow-auto" style={{ marginTop: isSmallWindow ? '2.5rem' : undefined }}>
                    <div className="p-5 h-full overflow-hidden">
                        <Outlet />
                    </div>
                </div>
            </div>
            </>
        </div>
    </AppSupportContext.Provider >;
}
