import { copyObject } from "utilities/Objects";

export default function migrate_from_v1(v1Garden: any): any {
    const gardenCopy = copyObject(v1Garden);
    gardenCopy.version = 2;
    gardenCopy.plants.forEach((p: any) => {
        p.plantsPerSq = p.plantsPerSqFt;
        delete p.plantsPerSqFt;
    });
    return gardenCopy;
}