const plantColors = ["#353715",
    "#3f4319",
    "#4a4e1d",
    "#545922",
    "#5f6426",
    "#696f2a",
    "#373b12",
    "#434715",
    "#4e5219",
    "#595e1c",
    "#646a20",
    "#6f7623",
    "#3a3e0e",
    "#464b11",
    "#525714",
    "#5d6417",
    "#697019",
    "#757d1c",
    "#3d420b",
    "#494f0d",
    "#565c0f",
    "#626911",
    "#6e7613",
    "#7a8415",
    "#404507",
    "#4d5309",
    "#5a610a",
    "#676f0c",
    "#737d0d",
    "#808a0f",
    "#434904",
    "#505705",
    "#5e6605",
    "#6b7406",
    "#788307",
    "#869108",
    "#464c00",
    "#545b00",
    "#626b01",
    "#707a01",
    "#7e8901",
    "#8c9801",
    "#2f3715",
    "#394319",
    "#424e1d",
    "#4c5922",
    "#556426",
    "#5f6f2a",
    "#313b12",
    "#3b4715",
    "#455219",
    "#4f5e1c",
    "#596a20",
    "#637623",
    "#333e0e",
    "#3d4b11",
    "#485714",
    "#526417",
    "#5c7019",
    "#667d1c",
    "#35420b",
    "#404f0d",
    "#4a5c0f",
    "#556911",
    "#5f7613",
    "#6a8415",
    "#374507",
    "#425309",
    "#4d610a",
    "#586f0c",
    "#637d0d",
    "#6e8a0f",
    "#394904",
    "#445705",
    "#4f6605",
    "#5b7406",
    "#668307",
    "#719108",
    "#3a4c00",
    "#465b00",
    "#526b01",
    "#5e7a01",
    "#698901",
    "#759801",
    "#2a3715",
    "#334319",
    "#3b4e1d",
    "#445922",
    "#4c6426",
    "#556f2a",
    "#2b3b12",
    "#344715",
    "#3c5219",
    "#455e1c",
    "#4e6a20",
    "#567623",
    "#2c3e0e",
    "#354b11",
    "#3d5714",
    "#466417",
    "#4f7019",
    "#587d1c",
    "#2d420b",
    "#364f0d",
    "#3f5c0f",
    "#476911",
    "#507613",
    "#598415",
    "#2d4507",
    "#375309",
    "#40610a",
    "#496f0c",
    "#527d0d",
    "#5b8a0f",
    "#2e4904",
    "#385705",
    "#416605",
    "#4a7406",
    "#538307",
    "#5d9108",
    "#2f4c00",
    "#395b00",
    "#426b01",
    "#4b7a01",
    "#558901",
    "#5e9801",
    "#253715",
    "#2d4319",
    "#344e1d",
    "#3b5922",
    "#436426",
    "#4a6f2a",
    "#253b12",
    "#2c4715",
    "#345219",
    "#3b5e1c",
    "#426a20",
    "#4a7623",
    "#253e0e",
    "#2c4b11",
    "#335714",
    "#3b6417",
    "#427019",
    "#497d1c",
    "#24420b",
    "#2c4f0d",
    "#335c0f",
    "#3a6911",
    "#427613",
    "#498415",
    "#244507",
    "#2b5309",
    "#33610a",
    "#3a6f0c",
    "#417d0d",
    "#488a0f",
    "#244904",
    "#2b5705",
    "#326605",
    "#3a7406",
    "#418307",
    "#489108",
    "#244c00",
    "#2b5b00",
    "#326b01",
    "#397a01",
    "#408901",
    "#479801",
    "#203715",
    "#264319",
    "#2d4e1d",
    "#335922",
    "#396426",
    "#406f2a",
    "#1f3b12",
    "#254715",
    "#2b5219",
    "#315e1c",
    "#376a20",
    "#3d7623",
    "#1d3e0e",
    "#234b11",
    "#295714",
    "#2f6417",
    "#357019",
    "#3b7d1c",
    "#1c420b",
    "#224f0d",
    "#275c0f",
    "#2d6911",
    "#337613",
    "#388415",
    "#1b4507",
    "#205309",
    "#26610a",
    "#2b6f0c",
    "#307d0d",
    "#368a0f",
    "#1a4904",
    "#1f5705",
    "#246605",
    "#297406",
    "#2e8307",
    "#339108",
    "#184c00",
    "#1d5b00",
    "#226b01",
    "#277a01",
    "#2c8901",
    "#319801",
    "#1b3715",
    "#204319",
    "#254e1d",
    "#2b5922",
    "#306426",
    "#366f2a",
    "#183b12",
    "#1d4715",
    "#225219",
    "#275e1c",
    "#2c6a20",
    "#317623",
    "#163e0e",
    "#1b4b11",
    "#1f5714",
    "#236417",
    "#287019",
    "#2c7d1c",
    "#14420b",
    "#184f0d",
    "#1c5c0f",
    "#206911",
    "#247613",
    "#288415",
    "#124507",
    "#155309",
    "#19610a",
    "#1c6f0c",
    "#207d0d",
    "#238a0f",
    "#0f4904",
    "#125705",
    "#156605",
    "#187406",
    "#1c8307",
    "#1f9108",
    "#0d4c00",
    "#105b00",
    "#126b01",
    "#157a01",
    "#178901",
    "#1a9801",
    "#163715",
    "#1a4319",
    "#1e4e1d",
    "#235922",
    "#276426",
    "#2b6f2a",
    "#123b12",
    "#164715",
    "#1a5219",
    "#1d5e1c",
    "#216a20",
    "#257623",
    "#0f3e0e",
    "#124b11",
    "#155714",
    "#186417",
    "#1b7019",
    "#1e7d1c",
    "#0c420b",
    "#0e4f0d",
    "#105c0f",
    "#136911",
    "#157613",
    "#178415",
    "#084507",
    "#0a5309",
    "#0c610a",
    "#0d6f0c",
    "#0f7d0d",
    "#118a0f",
    "#054904",
    "#065705",
    "#076605",
    "#087406",
    "#098307",
    "#0a9108",
    "#024c00",
    "#025b00",
    "#026b01",
    "#037a01",
    "#038901",
    "#039801",
    "#15371a",
    "#19431f",
    "#1d4e24",
    "#225929",
    "#26642e",
    "#2a6f33",
    "#123b17",
    "#15471c",
    "#195220",
    "#1c5e25",
    "#206a2a",
    "#23762e",
    "#0e3e15",
    "#114b19",
    "#14571d",
    "#176421",
    "#197025",
    "#1c7d29",
    "#0b4212",
    "#0d4f16",
    "#0f5c19",
    "#11691d",
    "#137620",
    "#158424",
    "#074510",
    "#095313",
    "#0a6116",
    "#0c6f19",
    "#0d7d1c",
    "#0f8a1f",
    "#04490d",
    "#055710",
    "#056612",
    "#067415",
    "#078317",
    "#08911a",
    "#004c0a",
    "#005b0d",
    "#016b0f",
    "#017a11",
    "#018913",
    "#019815",
    "#15371f",
    "#194325",
    "#1d4e2b",
    "#225931",
    "#266437",
    "#2a6f3e",
    "#123b1d",
    "#154723",
    "#195229",
    "#1c5e2f",
    "#206a35",
    "#23763b",
    "#0e3e1c",
    "#114b21",
    "#145727",
    "#17642c",
    "#197032",
    "#1c7d38",
    "#0b421a",
    "#0d4f20",
    "#0f5c25",
    "#11692a",
    "#13762f",
    "#158435",
    "#074519",
    "#09531e",
    "#0a6123",
    "#0c6f28",
    "#0d7d2d",
    "#0f8a32",
    "#044917",
    "#05571c",
    "#056621",
    "#067425",
    "#07832a",
    "#08912f",
    "#004c16",
    "#005b1a",
    "#016b1f",
    "#017a23",
    "#018927",
    "#01982c",
    "#153724",
    "#19432b",
    "#1d4e32",
    "#22593a",
    "#266441",
    "#2a6f48",
    "#123b23",
    "#15472b",
    "#195232",
    "#1c5e39",
    "#206a40",
    "#237647",
    "#0e3e23",
    "#114b2a",
    "#145731",
    "#176438",
    "#19703f",
    "#1c7d46",
    "#0b4223",
    "#0d4f29",
    "#0f5c30",
    "#116937",
    "#13763e",
    "#158445",
    "#074522",
    "#095329",
    "#0a6130",
    "#0c6f37",
    "#0d7d3d",
    "#0f8a44",
    "#044922",
    "#055728",
    "#05662f",
    "#067436",
    "#07833d",
    "#089143",
    "#004c21",
    "#005b28",
    "#016b2e",
    "#017a35",
    "#01893c",
    "#019842"];

export const randomPlantColor = () => {
    return plantColors[Math.floor(Math.random() * plantColors.length)];
}