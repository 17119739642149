import AppSelect from "components/common/app_select/AppSelect";
import PlantPerSqInput from "components/common/plant_per_sqft_input/PlantPerSqFtInput";
import Garden from "data/models/Garden";
import GardenPlant from "data/models/GardenPlant";
import SowingMethod from "data/models/SowingMethod";
import { useMemo } from "react";
import { weeksFromDate } from "utilities/Dates";

type PlantRowProps = {
    plant: GardenPlant;
    garden: Garden;
    updatePlant: (plant: GardenPlant) => void;
};

export default function PlantRow(props: PlantRowProps) {
    const { garden, plant, updatePlant } = props;

    const sowOptions = useMemo(() => [
        { value: 'direct', label: 'Starting from seed outdoors' },
        { value: 'startingIndoors', label: 'Starting from seed indoors' },
        { value: 'transplanting', label: 'Planting a grown plant outdoors' }
    ], []);

    return <tr>
        <th><input
            type="text"
            name="plant-name"
            id="plant-name"
            value={plant.name ?? ""}
            onChange={(e) => updatePlant({ ...plant, name: e.target.value })}
            className="block w-24 mb-[20px] text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        /></th>
        <td>
            <AppSelect className=" mb-[20px]" value={plant.sow} options={sowOptions} onChange={e => updatePlant({ ...plant, sow: e.target.value as SowingMethod })} />
        </td>
        <td>
            <PlantPerSqInput className=" mb-[20px]" initialValue={plant.plantsPerSq} onChange={v => updatePlant({ ...plant, plantsPerSq: v })} />
        </td>
        <td>
            {(plant.sow === "startingIndoors" || plant.sow === "direct") ? <><input
                type="number"
                value={isNaN(plant.seedsPerPlant) ? "" : plant.seedsPerPlant}
                onChange={(e) => updatePlant({ ...plant, seedsPerPlant: e.target.valueAsNumber })}
                className="block w-full mb-[20px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            /></> : <span className="text-gray-200">-</span>}
        </td>
        <td>
            {plant.sow === "direct" ? <><input
                type="number"
                value={isNaN(plant.outdoorWeeks) ? "" : plant.outdoorWeeks}
                onChange={(e) => updatePlant({ ...plant, outdoorWeeks: e.target.valueAsNumber })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
                <span className="mt-1 block text-right text-xs font-light text-gray-500">
                    ~ {weeksFromDate(garden!.details.seasonStart!, plant.outdoorWeeks).toFormat("DDD")}
                </span>
            </> : <span className="text-gray-200">-</span>}
        </td>
        <td>
            {plant.sow === "startingIndoors" ? <><input
                type="number"
                value={isNaN(plant.indoorWeeks) ? "" : plant.indoorWeeks}
                onChange={(e) => updatePlant({ ...plant, indoorWeeks: e.target.valueAsNumber })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
                <span className="mt-1 block text-right text-xs font-light text-gray-500">
                    ~ {weeksFromDate(garden!.details.seasonStart!, plant.indoorWeeks).toFormat("DDD")}
                </span></> : <span className="text-gray-200">-</span>}
        </td>
        <td>
            {(plant.sow === "startingIndoors" || plant.sow === "transplanting") ? <><input
                type="number"
                value={isNaN(plant.transplantWeeks) ? "" : plant.transplantWeeks}
                onChange={(e) => updatePlant({ ...plant, transplantWeeks: e.target.valueAsNumber })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
                <span className="mt-1 block text-right text-xs font-light text-gray-500">
                    ~ {weeksFromDate(garden!.details.seasonStart!, plant.transplantWeeks).toFormat("DDD")}
                </span></> : <span className="text-gray-200">-</span>}
        </td>
    </tr>;
}