import GardenPlant from "data/models/GardenPlant";
import { IGardenLayout, PaintMode } from "./Types";
import PlantableUnit from "data/models/PlantableUnit";

export const getCellDetails = (
  row: number,
  col: number,
  layout: IGardenLayout
) => {
  if (!layout[row]) {
    return null;
  }

  return layout[row][col];
};

export const updatePlantable = (
  row: number,
  col: number,
  dragging = false,
  planted: IGardenLayout,
  paintMode: PaintMode
) => {
  if (!planted[row]) {
    planted[row] = {};
  }

  if (!planted[row][col]) {
    planted[row][col] = { plantable: false, planted: null };
  }

  const currentValue = planted[row][col].plantable;
  if (!dragging) {
    planted[row][col].plantable = !currentValue;
  } else {
    planted[row][col].plantable = paintMode == "placing";
  }

  if (!planted[row][col].plantable) {
    planted[row][col].planted = null;
  }

  const newPlanted = Object.assign({}, planted);
  return newPlanted;
};

export const updatePlanted = (
  row: number,
  col: number,
  dragging = false,
  planted: IGardenLayout,
  paintMode: PaintMode,
  currentPlant: GardenPlant | null
) => {
  if (!planted[row]) {
    planted[row] = {};
  }

  if (!planted[row][col]) {
    planted[row][col] = { plantable: false, planted: null };
  }

  if (!dragging) {
    if (currentPlant && planted[row][col].planted?.id === currentPlant.id) {
      planted[row][col].planted = null;
    } else {
      planted[row][col].planted = currentPlant;
    }
  } else {
    planted[row][col].planted = paintMode == "placing" ? currentPlant : null;
  }

  if (planted[row][col].planted) {
    planted[row][col].plantable = true;
  }

  const newPlanted = Object.assign({}, planted);
  return newPlanted;
};

export const rebuildLayout = (
  layout: IGardenLayout,
  rows: number,
  cols: number
) => {
  const newLayout: IGardenLayout = {};

  for (let row = 0; row < rows; row++) {
    newLayout[row] = [];

    for (let col = 0; col < cols; col++) {
      const oldCell = getCellDetails(row, col, layout);
      newLayout[row][col] = oldCell || { plantable: false, planted: null };
    }
  }

  return newLayout;
};

export const layoutToUnits = (
  layout: IGardenLayout,
  rows: number,
  cols: number
) => {
  const units: PlantableUnit[] = [];
  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      const cellDetails = getCellDetails(row, col, layout);
      if (cellDetails && (cellDetails.plantable || cellDetails.planted)) {
        units.push({ x: col, y: row, plantId: cellDetails.planted?.id });
      }
    }
  }
  return units;
};

export const unitsToLayout = (
  units: PlantableUnit[],
  plants: GardenPlant[] | null
) => {
  const layout: IGardenLayout = {};

  let maxRows = 2;
  let maxCols = 2;

  for (const unit of units) {
    maxRows = Math.max(unit.y + 1, maxRows);
    maxCols = Math.max(unit.x + 1, maxCols);
  }

  for (let row = 0; row < maxRows; row++) {
    layout[row] = [];

    for (let col = 0; col < maxCols; col++) {
      layout[row][col] = { plantable: false, planted: null };
    }
  }

  for (const unit of units) {
    layout[unit.y][unit.x].plantable = true;
    if (unit.plantId) {
      const plant = plants?.find((p) => p.id == unit.plantId);
      layout[unit.y][unit.x].planted = plant ?? null;
    }
  }

  return layout;
};
