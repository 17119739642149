import classNames from "classnames";
import { AppContext } from "components/app_root/AppContext";
import { useContext } from "react";

const Title = (props: { hideIfSmall?: boolean } & React.HTMLAttributes<HTMLParagraphElement>) => {
    const { isSmallWindow } = useContext(AppContext);
    const { hideIfSmall, ...rest } = props;

    if (props.hideIfSmall && isSmallWindow) return null;

    return <h1 {...rest} className={classNames(`text-h2 font-light text-bright`, props.className)}>{props.children}</h1>;
}

export default Title;