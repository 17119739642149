import { createContext } from "react";

export type GardensContextType = {
  title: string | null;
  setTitle: ((title: string) => void) | undefined;
};
export const GardensContext = createContext<GardensContextType>({
  title: "",
  setTitle: undefined,
});
