import { useCallback } from "react";
import { Link } from "react-router-dom";

const AppButtonListButton = (props: { onClick?: () => void, to?: string } & React.PropsWithChildren) => {
    const handleButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick?.();
    }, [props.onClick]);

    const handleLinkClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        props.onClick?.();
    }, [props.onClick]);

    if (props.to === undefined) {
        return <button
            className="transition-colors duration-default mb-2 rounded-md last-of-type:mb-0 p-3 w-full block text-left text-primary bg-main-background-secondary cursor-pointer hover:bg-main-background-secondary-hover space-y-2"
            onClick={handleButtonClick}>
            {props.children}
        </button>;
    }

    return <Link
        to={props.to}
        className="transition-colors duration-default mb-2 rounded-md last-of-type:mb-0 p-3 w-full block text-left text-primary bg-main-background-secondary cursor-pointer hover:bg-main-background-secondary-hover space-y-2"
        onClick={handleLinkClick}>
        {props.children}
    </Link>;
}

export default AppButtonListButton;