import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { allowedBelowOneValues } from "./PlantsPerSqFtUtilities";

type PlantPerSqInputProps = {
    initialValue: number;
    className?: string;
    onChange: (value: number) => void;
};

const PlantPerSqInput = (props: PlantPerSqInputProps) => {
    let initialBelowOneValue = allowedBelowOneValues.findIndex(v => v.value === props.initialValue);
    if (initialBelowOneValue === -1) {
        initialBelowOneValue = allowedBelowOneValues.length;
    }

    const [belowOneIndex, setBelowOneIndex] = useState(initialBelowOneValue);
    const [plantsPerSq, setPlantsPerSq] = useState(props.initialValue);

    const decreaseValue = useCallback(() => {
        if (plantsPerSq <= 1) {
            if (belowOneIndex === 0) {
                return;
            }

            setBelowOneIndex(_ => belowOneIndex - 1);
            setPlantsPerSq(_ => allowedBelowOneValues[belowOneIndex - 1].value);
            props.onChange(allowedBelowOneValues[belowOneIndex - 1].value);
        } else {
            setPlantsPerSq(_ => plantsPerSq - 1);
            props.onChange(plantsPerSq - 1);
        }
    }, [plantsPerSq, belowOneIndex, props.onChange]);

    const increaseValue = useCallback(() => {
        if (plantsPerSq < 1) {
            if (belowOneIndex === allowedBelowOneValues.length - 1) {
                setBelowOneIndex(_ => allowedBelowOneValues.length);
                setPlantsPerSq(_ => 1);
                props.onChange(1);
            } else {
                setBelowOneIndex(_ => belowOneIndex + 1);
                setPlantsPerSq(_ => allowedBelowOneValues[belowOneIndex + 1].value);
                props.onChange(allowedBelowOneValues[belowOneIndex + 1].value);
            }
        } else {
            setPlantsPerSq(_ => plantsPerSq + 1);
            props.onChange(plantsPerSq + 1);
        }
    }, [plantsPerSq, belowOneIndex, props.onChange]);

    const label = useMemo(() => {
        if (plantsPerSq >= 1) {
            return `${plantsPerSq} plant${plantsPerSq === 1 ? "" : "s"} per square unit`;
        } else {
            return allowedBelowOneValues[belowOneIndex].label;
        }
    }, [belowOneIndex, plantsPerSq])

    return <div className={classNames("bg-main-background-secondary rounded-md flex flex-row justify-center overflow-hidden", props.className)}>
        <button type="button" className={classNames("flex items-center justify-center cursor-pointer w-8 leading-8 bg-main-background-secondary bg-opacity-10", belowOneIndex === 0 ? "opacity-10" : "")} onClick={decreaseValue}><FontAwesomeIcon icon={faMinus} /></button>
        <span className="block leading-8 px-4 text-sm text-center flex-1">{label}</span>
        <button type="button" className="flex items-center justify-center cursor-pointer w-8 leading-8 bg-main-background-secondary bg-opacity-10" onClick={increaseValue}><FontAwesomeIcon icon={faPlus} /></button>
    </div>
}

export default PlantPerSqInput;
