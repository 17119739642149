import classNames from "classnames";
import { Link } from "react-router-dom";

const MenuItem = (props: { to?: string, selected?: boolean } & React.HTMLAttributes<HTMLAnchorElement>) => {
    if (!props.to) {
        return <a {...props} href="#" className={classNames("transition-colors duration-default cursor-pointer block w-full text-left p-2 hover:bg-menu-background-hover rounded-md", props.className, props.selected ? "bg-menu-background-selected font-bold" : "")}>{props.children}</a>
    }

    return <Link {...props} to={props.to!} className={classNames("transition-colors duration-default cursor-pointer block w-full text-left p-2 hover:bg-menu-background-hover rounded-md", props.className, props.selected ? "bg-menu-background-selected font-bold" : "")}>{props.children}</Link>;
}

export default MenuItem;