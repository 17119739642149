import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import Services from "services/Services";

export function usePrevious<T>(value: T) {
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
}

export function useStateWithPrevious<T>(value: T): [T, (value: T) => void, T] {
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const [currentValue, setter] = useState(value);

  return [currentValue, setter, valueRef.current];
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useWindowVisible(): boolean {
  const [visible, setVisible] = useState(Services.WindowVisibility?.focused() ?? true);

  useEffect(() => {
    const onVisibilityChanged = () => setVisible(Services.WindowVisibility?.focused() ?? true);

    document.addEventListener('visibilitychange', onVisibilityChanged);
    window.onblur = onVisibilityChanged;
    window.onfocus = onVisibilityChanged;

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
      window.onblur = null;
      window.onfocus = null;
    };
  }, []);

  return visible;
}

export function useQueryParams() {
  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params
}
