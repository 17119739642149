import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faHandHoldingHeart } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Title from "components/common/title/Title"
import { AppSupportContext } from "pages/app/AppSupportContext";
import { useContext, useEffect } from "react";

export const MoreInfoPage = () => {
    const ctx = useContext(AppSupportContext);

    useEffect(() => {
        ctx.setTitle?.("Hi!");
    }, []);

    return <div className="flex flex-col h-full w-full">
        <Title className="text-2xl mb-2">Hi!</Title>
        <div className="p-4 flex-1 rounded-md overflow-auto bg-white">
            <h1 className="text-3xl font-bold text-button-primary-bg">I'm Josh. 🧑‍🌾</h1>
            <div className="mt-5 text-secondary space-y-5">
                <p>I love gardening - and I love sharing it.</p>
                <p>That's why I built Squardener - first to help myself plan my garden, then to share it with the world.</p>
                <p className="font-bold text-xl flashy-text">I'm here to build a thriving and deeply connected world - connected to ourselves, to our environment, and to each other.</p>
                <p className="text-4xl text-center">🧘 🌎 👪</p>
                <p>Squardener is one small way I'm doing that: to help plant a garden, grow amazing things, and share those amazing things with each other.</p>
                <p className="font-extrabold text-3xl flashy-text">You can help!</p>
                <p>We can make Squardener <span className="font-bold">tons better</span> together with your help - if you would tell me what you love 🥳, what you don't 🫤, and what you'd like to see 💡!</p>
                <p>Just email me: <a href="mailto:josh@squardener.com" className=" font-bold text-button-primary-bg hover:text-button-primary-bg-hover">josh@squardener.com</a></p>
                {/* <div>
                    <div className="flex flex-row items-center font-extrabold text-xl mb-2"><FontAwesomeIcon icon={faInstagram} className="mr-2 text-pink-600" /> <div className="flex-1 text-button-primary-bg">On Instagram</div></div>
                    <span>I'm also sharing my journey, tips, and mishaps on Instagram and would love your company: <a className="text-sm flex-1 text-center leading-6 font-bold px-2 rounded-md bg-button-primary-bg hover:bg-button-primary-bg-hover text-button-fg h-6 ml-1 inline-block" href="https://www.instagram.com/squardener/" target="_blank"><h1>@squardener</h1></a></span>
                </div> */}
                <div>
                    <div className="flex flex-row items-center font-extrabold text-xl mb-2"><FontAwesomeIcon icon={faHandHoldingHeart} className="mr-2 text-red-400" /><div className="text-button-primary-bg flex-1">Your donations make Squardener better!</div></div>
                    <span>Your donations help me dedicate more time to Squardener and are extremely appreciated: <a className="text-sm text-center leading-6 font-bold px-2 rounded-md bg-button-primary-bg hover:bg-button-primary-bg-hover text-button-fg h-6 ml-1 inline-block" href="https://www.buymeacoffee.com/squardener" target="_blank"><h1><FontAwesomeIcon icon={faHandHoldingHeart} className="mr-1" /> Help grow Squardener!</h1></a></span>
                </div>
                <p>You've filled my heart with joy if Squardener helps make gardening and growing your own <span className=" text-red-700 font-bold">fruits</span>, <span className=" text-green-700 font-bold">veggies</span>, <span className=" text-yellow-600 font-bold">flowers</span>, <span className=" text-purple-800 font-bold">herbs</span>, and <span className="font-bold">more</span> easier and funner for you.</p>
                <p className="text-4xl text-center">🍓 🥬 🌻</p>
            </div>
        </div>
    </div>
}