import { faBars, faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "components/common/app_menu/MenuItem";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GardenContext } from "./GardenContext";
import { AppContext } from "components/app_root/AppContext";
import MenuWrapper from "components/menu_wrapper/MenuWrapper";
import Subtitle from "components/common/subtitle/Subtitle";
import { faBagSeedling, faBasketShopping, faCalendar, faFlower, faCog, faSeedling, faCalendarAlt, faUser, faQuestionCircle, faInfoCircle, faShare } from "@fortawesome/pro-solid-svg-icons";
import Divider from "components/common/divider/Divider";
import GardenSettingsPanel from "./sub_pages/planner/GardenSettingsPanel";
import ColorLogo from "components/common/logo/ColorLogo";
import { faAddressCard, faHeart, faInfoSquare, faSquareQuestion } from "@fortawesome/pro-duotone-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import SharingButtons from "components/sharing_buttons/SharingButtons";

const GardenMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ctx = useContext(GardenContext);
    const { isSmallWindow } = useContext(AppContext);
    const menuName = ctx.title ?? "Garden Planner";
    const [showSettingsPanel, setShowSettingsPanel] = useState(false);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [isSmallWindow])

    const closeMenu = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        setIsMenuOpen(_ => false);
    }, [setIsMenuOpen]);

    const common = <>
        <GardenSettingsPanel
            show={showSettingsPanel}
            onClose={() => setShowSettingsPanel(false)} /></>;

    if (!isSmallWindow) {
        return <MenuWrapper className="m-5 mr-0">
            <div className="flex flex-row pb-8 items-center justify-center">
                <ColorLogo className="w-8 h-8 mr-2" />
                <div className="cursor-default select-none">
                    <h1 className="font-bold text-xl text-main-background-tertiary">Squardener</h1>
                </div>
            </div>
            <Subtitle className="block pb-2">
                <p className="font-semibold mb-1">{ctx.garden?.details.name}</p>
                <p>{ctx.title}</p>
            </Subtitle>
            {common}
            <div className=" space-y-4 text-sm flex-1 overflow-auto">
                <MenuItem to="planner" selected={menuName === "Garden Planner"}><FontAwesomeIcon icon={faBagSeedling} className="mr-1 w-4 opacity-secondary text-yellow-700" /> Garden Planner</MenuItem>
                <MenuItem to="plants" selected={menuName === "Plant Editor"}><FontAwesomeIcon icon={faSeedling} className="mr-1 w-4 opacity-secondary text-green-700" /> Plant Editor</MenuItem>
                <MenuItem to="schedule" selected={menuName === "Planting Schedule"}><FontAwesomeIcon icon={faCalendarAlt} className="mr-1 w-4 opacity-secondary text-orange-700" /> Planting Schedule</MenuItem>
                <MenuItem to="shopping" selected={menuName === "Shopping List"}><FontAwesomeIcon icon={faBasketShopping} className="mr-1 w-4 opacity-secondary text-blue-900" /> Shopping List</MenuItem>
                <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowSettingsPanel(true); }} ><FontAwesomeIcon icon={faCog} className="mr-1 w-4 opacity-secondary text-slate-900" /> Garden Settings</MenuItem>
                <Divider className="my-5" />
                <MenuItem to="/gardens"><FontAwesomeIcon icon={faFlower} className="mr-1 w-4 opacity-secondary text-pink-900" /> Your Gardens</MenuItem>
            </div>
            <div>
                <MenuItem to="/app/info" selected={menuName === "ZZZ"} className="flex flex-row items-center bg-main-background-secondary">
                    <FontAwesomeIcon icon={faHeart} className="mr-4 w-6 h-6 opacity-secondary text-red-600" />
                    <div className="flex-1 text-sm font-bold">You're important and can help me build Squardener!</div>
                </MenuItem>
                <SharingButtons />
            </div>
        </MenuWrapper>;
    }

    return <>
        <div className="absolute w-full p-2 px-5">
            <div key="title-bar" className="bg-menu-background shadow-xl font-light flex flex-row items-center text-sm rounded-md leading-10 h-10 align-baseline cursor-pointer relative" style={{ zIndex: 9 }} onClick={() => setIsMenuOpen(true)}>
                <ColorLogo className="w-6 h-6 ml-2 block opacity-50" />
                <span className="text-xs opacity-tertiary block flex-1 text-center"><span className="font-semibold">{ctx.garden?.details.name}</span> - {ctx.title}</span>
                {!isMenuOpen && <button key="open-button" className="absolute text-primary z-10 rounded-e-md right-0 leading-10 w-10 h-10 align-baseline" onClick={() => setIsMenuOpen(true)}>
                    <FontAwesomeIcon icon={faBars} />
                </button>}
            </div>
        </div>
        {isMenuOpen && <>
            <div className="absolute w-full h-full overflow-auto z-20">
                <div className="fixed inset-0 h-full w-full bg-black opacity-50" onClick={() => setIsMenuOpen(false)}></div>
                <MenuWrapper key="open-menu" className="w-full max-h-dvh pr-12 flex">
                    <div className="flex flex-row pb-6 items-center justify-center">
                        <ColorLogo className="w-8 h-8 mr-2" />
                        <div className="cursor-default select-none">
                            <h1 className="font-bold text-xl text-main-background-tertiary">Squardener</h1>
                        </div>
                    </div>
                    <Subtitle className="block p-2 pb-4"><span className="font-semibold">{ctx.garden?.details.name}</span> - {ctx.title}</Subtitle>
                    <div className=" space-y-4 text-sm flex-1 overflow-auto">
                        <MenuItem to="planner" onClick={closeMenu} selected={menuName === "Garden Planner"}><FontAwesomeIcon icon={faBagSeedling} className="mr-1 w-4 opacity-secondary text-yellow-700" /> Garden Planner</MenuItem>
                        <MenuItem to="plants" onClick={closeMenu} selected={menuName === "Plant Editor"}><FontAwesomeIcon icon={faSeedling} className="mr-1 w-4 opacity-secondary text-green-700" /> Plant Editor</MenuItem>
                        <MenuItem to="schedule" onClick={closeMenu} selected={menuName === "Planting Schedule"}><FontAwesomeIcon icon={faCalendarAlt} className="mr-1 w-4 opacity-secondary text-orange-700" /> Planting Schedule</MenuItem>
                        <MenuItem to="shopping" onClick={closeMenu} selected={menuName === "Shopping List"}><FontAwesomeIcon icon={faBasketShopping} className="mr-1 w-4 opacity-secondary text-blue-900" /> Shopping List</MenuItem>
                        <MenuItem onClick={(e: React.MouseEvent<HTMLAnchorElement>) => { e.preventDefault(); e.stopPropagation(); setIsMenuOpen(false); setShowSettingsPanel(true); }} ><FontAwesomeIcon icon={faCog} className="mr-1 w-4 opacity-secondary text-slate-900" /> Garden Settings</MenuItem>
                        <Divider className="my-5" />
                        <MenuItem to="/gardens"><FontAwesomeIcon icon={faFlower} className="mr-1 w-4 opacity-secondary text-pink-900" /> Your Gardens</MenuItem>
                        <MenuItem to="/app/info" selected={menuName === "ZZZ"} className="flex flex-row items-center bg-main-background-secondary mt-5">
                            <FontAwesomeIcon icon={faHeart} className="mr-4 w-6 h-6 opacity-secondary text-red-600" />
                            <div className="flex-1 text-sm font-bold">You're important and can help me build Squardener!</div>
                        </MenuItem>
                        <SharingButtons />
                    </div>
                </MenuWrapper>
                <button key="close-button" className="text-primary fixed z-20 right-2 top-2 leading-10 w-10 h-10 align-baseline rounded-e-md" onClick={() => setIsMenuOpen(false)}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </div>
        </>}
        {common}
    </>;
}

export default GardenMenu;