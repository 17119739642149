import { AppContext } from "./AppContext";
import { Breakpoint } from "utilities/Grid";
import { useWindowSize } from "../../hooks";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function AppRoot(props: React.PropsWithChildren) {
    const windowSize = useWindowSize();
    const isSmallWindow = windowSize.width < Breakpoint.sm || windowSize.height < Breakpoint.sm;

    return (
        <AppContext.Provider value={{ isSmallWindow: isSmallWindow }}>
            <div className="relative flex h-dvh max-h-dvh min-h-dvh flex-col bg-background-primary">
                {props.children}
            </div>
        </AppContext.Provider>
    );
}
