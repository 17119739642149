import GardenPlant from "data/models/GardenPlant";
import { GardenContext } from "pages/garden/GardenContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { weeksFromDate } from "utilities/Dates";
import ScheduleBlock from "./ScheduleBlock";
import AppButton from "components/common/app_button/AppButton";
import { useNavigate } from "react-router-dom";
import Title from "components/common/title/Title";
import { ScheduleContext } from "./ScheduleContext";
import PlantMapModel from "./PlantMapModal";

export type PlantingDate = {
  date: string;
  dateNumber: number;
  plants: PlantingUnit[];
};

export type PlantingUnit = {
  plant: GardenPlant;
  type: "direct" | "startingIndoors" | "transplanting";
};

const SchedulePage = () => {
  const ctx = useContext(GardenContext);
  const navigate = useNavigate();
  const [showMap, setShowMap] = useState<boolean>(false);
  const [plantIdForMap, setPlantIdForMap] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    ctx.setTitle?.("Planting Schedule");
  }, []);

  const plantingDates: PlantingDate[] = useMemo(() => {
    const dates: PlantingDate[] = [];
    ctx.garden?.plants?.forEach((plant) => {
      const units =
        ctx.garden?.units.filter((u) => u.plantId === plant.id) ?? [];
      if (units.length === 0) {
        return;
      }

      const weeks: number[] = [];

      if (plant.sow === "direct") {
        weeks.push(plant.outdoorWeeks);
      }

      if (plant.sow === "startingIndoors") {
        weeks.push(plant.indoorWeeks);
        weeks.push(plant.transplantWeeks);
      }

      if (plant.sow === "transplanting") {
        weeks.push(plant.transplantWeeks);
      }

      for (let i = 0; i < weeks.length; i++) {
        const date = weeksFromDate(ctx.garden!.details.seasonStart!, weeks[i]);
        const fmtDate = date.toFormat("DDD");
        let plantingDate = dates.find((d) => d.date === fmtDate);
        if (!plantingDate) {
          plantingDate = {
            date: fmtDate,
            dateNumber: date.toSeconds(),
            plants: [],
          };
          dates.push(plantingDate);
        }
        const plantingUnit: PlantingUnit = {
          plant,
          type: i === 1 ? "transplanting" : plant.sow,
        };
        plantingDate.plants.push(plantingUnit);
      }
    });

    return dates.sort((a, b) => a.dateNumber - b.dateNumber);
  }, [ctx.garden]);

  return (
    <ScheduleContext.Provider value={{ plantIdForMap, setPlantIdForMap, showMap, setShowMap }}>
      <div className="flex flex-col h-full w-full">
        <Title className="text-2xl mb-2">Planting Schedule</Title>
        {plantingDates.length === 0 && (
          <div className="flex flex-col items-center justify-center pt-8">
            <div className="text-center p-4 rounded-md">
              <h1 className="font-semibold mb-2">
                You don't have any planted plants, yet!
              </h1>
              <p className="mb-2">
                Create some plants and put them in your garden from the Garden
                Planner.
              </p>
              <AppButton
                buttonType="primary"
                onClick={() => navigate("../planner")}
              >
                Get planning now!
              </AppButton>
            </div>
          </div>
        )}
        {plantingDates.length > 0 && (
          <>
            <div className="p-4 flex-1 rounded-md overflow-hidden bg-white">
              <div className="overflow-auto w-full h-full">
                {plantingDates.map((pd) => (
                  <ScheduleBlock key={pd.date} plantingDate={pd} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <PlantMapModel
        show={showMap}
        showPlantId={plantIdForMap}
        onClose={() => setShowMap(false)}
      />
    </ScheduleContext.Provider>
  );
};

export default SchedulePage;
