import { GardenContext } from "pages/garden/GardenContext";
import { useContext, useEffect, useState } from "react";
import PlannerGrid from "./PlannerGrid";
import PlannerTools from "./PlannerTools";
import { PlannerContext } from "./PlannerContext";
import { EditMode, PaintMode } from "./utilities/Types";
import GardenPlant from "data/models/GardenPlant";
import HelpModal from "components/help_modal/HelpModal";

const PlannerPage = () => {
    const ctx = useContext(GardenContext);

    useEffect(() => {
        ctx.setTitle?.("Garden Planner");
    }, []);

    const [currentPlant, setCurrentPlant] = useState<GardenPlant | undefined | null>(undefined);
    const [painting, setPainting] = useState(false);
    const [paintMode, setPaintMode] = useState<PaintMode>("placing");
    const [mode, setMode] = useState<EditMode>("shaping");
    const [highlightCurrent, setHighlightCurrent] = useState(false);
    const [showLabels, setShowLabels] = useState(false);

    return (<>{ctx.garden &&
        <PlannerContext.Provider value={{
            currentPlant,
            setCurrentPlant,
            painting,
            setPainting,
            paintMode,
            setPaintMode,
            mode,
            setMode,
            highlightCurrent,
            setHighlightCurrent,
            showLabels,
            setShowLabels
        }}>
            <div className="relative mx-auto w-full flex flex-col h-full overflow-hidden">
                <div>
                    <PlannerTools />
                </div>
                <div className="overflow-auto p-4 pr-0 flex-1 bg-white rounded-md relative">
                    <PlannerGrid rows={ctx.garden.details.rows} columns={ctx.garden.details.columns} garden={ctx.garden!} />
                </div>
            </div>
        </PlannerContext.Provider>
    }</>);
};

export default PlannerPage;