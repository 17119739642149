import { CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { forwardRef, useCallback, useContext, useEffect, useState } from "react";
import { NotificationContext } from "./NotificationContext";
import { NotificationData } from "./NotificationData";
import { useLocation } from "react-router-dom";

const NotifyItem = forwardRef(function NotifyItem(props: { notification: NotificationData }, forwardedRef: any) {
    const context = useContext(NotificationContext);
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);

    const onClose = useCallback(() => {
        context.removeNotification?.(props.notification.id);
    }, [context.notifications, context.removeNotification]);

    useEffect(() => {
        if (props.notification.closeOnNavigate && currentLocation !== location.pathname) {
            onClose();
        }
    }, [location.pathname]);

    return <div ref={forwardedRef} className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
            <div className="flex items-start">
                <div className="flex-shrink-0">
                    {props.notification.type === "success" ? <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> : <ExclamationTriangleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{props.notification.title}</p>
                    <p className="mt-1 text-sm text-gray-500">{props.notification.message}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                    <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={onClose}
                    >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>;

})
export default NotifyItem;