export const breakpoints = {
    xs: '0',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
}

export enum Breakpoint {
    xs = 0,
    sm = 640,
    md = 768,
    lg = 1024,
    xl = 1280
}

export const windowAboveBreakpoint = (point: Breakpoint) => {
    const width = window.innerWidth;
    return width > point;
}

export const windowBelowBreakpoint = (point: Breakpoint) => {
    const width = window.innerWidth;
    return width < point;
}