import { v4 as uuidv4 } from "uuid";
import Garden from "data/models/Garden";
import { timestampToDateTime } from "utilities/Dates";
import { copyObject } from "utilities/Objects";

export default function validateV2(data: any): { errors?: string[], garden?: Garden } {
    const errors: string[] = [];

    if (typeof data !== "object") {
        errors.push("data is not an object");
    }

    if (data) {
        if (typeof data.version !== "number") {
            errors.push("data.version is not a number");
        }

        if (typeof data.id !== "string") {
            errors.push("data.id is not a string");
        }

        if (typeof data.details !== "object") {
            errors.push("data.details is not an object");
        }

        if (typeof data.units !== "object" || !Array.isArray(data.units)) {
            errors.push("data.units is not an array");
        }

        if (typeof data.plants !== "object" || !Array.isArray(data.plants)) {
            errors.push("data.plants is not an array");
        }

        if (typeof data.shopping !== "object" || !Array.isArray(data.shopping)) {
            errors.push("data.shopping is not an array");
        }

        if (typeof data.created !== "number") {
            errors.push("data.created is not a number");
        }
    }

    if (typeof data?.details === "object") {
        const detailsMap: { [key: string]: string } = {
            'name': 'string',
            'seasonStart': 'string',
            'seasonEnd': 'string',
            'rows': 'number',
            'columns': 'number'
        }

        for (const key in detailsMap) {
            if (typeof data.details[key] !== detailsMap[key]) {
                errors.push(`data.details.${key} is not a ${detailsMap[key]}`);
            }
        }

        if (isNaN(data.details.rows) || isNaN(data.details.columns)) {
            errors.push("data.details.rows or data.details.columns is not a number");
        }

        if (data.details.rows < 2 || data.details.columns < 2) {
            errors.push("data.details.rows or data.details.columns is less than 2");
        }

        if (data.details.rows > 20 || data.details.columns > 20) {
            errors.push("data.details.rows or data.details.columns is greater than 20");
        }

        if (timestampToDateTime(data.details.seasonStart!) >= timestampToDateTime(data.details.seasonEnd!)) {
            errors.push("data.details.seasonStart is greater than or equal to data.details.seasonEnd");
        }
    }

    if (typeof data.units === "object" && Array.isArray(data.units)) {
        const unitMap: { [key: string]: string[] } = {
            'x': ['number'],
            'y': ['number'],
            'plantId': ['string', 'undefined', 'null']
        }

        for (const unit of data.units) {
            for (const key in unitMap) {
                if (!unitMap[key].includes(typeof unit[key])) {
                    errors.push(`data.units[x=${unit.x},y=${unit.y}].${key} is not a ${unitMap[key].join(" or ")}`);
                }
            }
        }
    }

    if (typeof data.plants === "object" && Array.isArray(data.plants)) {
        const plantMap: { [key: string]: string } = {
            'id': 'string',
            'name': 'string',
            'color': 'string',
            'sow': 'string',
            'indoorWeeks': 'number',
            'outdoorWeeks': 'number',
            'transplantWeeks': 'number',
            'plantsPerSq': 'number',
            'seedsPerPlant': 'number',
        }

        for (const plant of data.plants) {
            for (const key in plantMap) {
                if (typeof plant[key] !== plantMap[key]) {
                    errors.push(`data.plants[id=${plant.id}].${key} is not a ${plantMap[key]}`);
                }
            }
        }

        const plantKeyValueMaps: { [key: string]: string[] } = {
            'sow': ['direct', 'startingIndoors', 'transplanting']
        }

        for (const plant of data.plants) {
            for (const key in plantKeyValueMaps) {
                if (!plantKeyValueMaps[key].includes(plant[key])) {
                    errors.push(`data.plants[id=${plant.id}].${key} is not a ${plantKeyValueMaps[key].join(" or ")}`);
                }
            }
        }
    }

    if (typeof data.shopping === "object" && Array.isArray(data.shopping)) {
        const shoppingMap: { [key: string]: string } = {
            'plantId': 'string',
            'created': 'number'
        }

        for (const shopping of data.shopping) {
            for (const key in shoppingMap) {
                if (typeof shopping[key] !== shoppingMap[key]) {
                    errors.push(`data.shopping[plantId=${shopping.plantId}].${key} is not a ${shoppingMap[key]}`);
                }
            }
        }
    }

    if (errors.length > 0) {
        console.log("Validation errors: ", errors);
        return { errors };
    }

    return { garden: transferV2ToGarden(data) };
}

function transferV2ToGarden(data: any): Garden {
    const garden = copyObject(data) as Garden;
    const id = uuidv4();
    garden.id = id;

    const plantIdMap: { [key: string]: string } = {};

    garden.plants.forEach(plant => {
        const newId = uuidv4();
        plantIdMap[plant.id] = newId;
        plant.id = newId;
    });

    garden.units.forEach(unit => {
        if (unit.plantId) {
            unit.plantId = plantIdMap[unit.plantId];
        }
    });

    garden.shopping.forEach(shopping => {
        shopping.plantId = plantIdMap[shopping.plantId];
    });

    return garden;
}