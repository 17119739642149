import { v4 as uuidv4 } from "uuid";
import { CURRENT_GARDEN_VERSION } from "data/local/LocalDb";
import Garden from "data/models/Garden";

const sampleGarden: () => Garden = () => {
    const year = new Date().getFullYear();
    const garden: Garden = {
        "id": uuidv4(),
        "details": {
            "name": `Josh's Spring ${year} Garden`,
            "seasonStart": `${year}-04-20`,
            "seasonEnd": `${year}-10-19`,
            "rows": 10,
            "columns": 10
        },
        "units": [
            {
                "x": 0,
                "y": 0,
                "plantId": "1045f900-4c97-4bf3-a06f-485a2c7fe2d9"
            },
            {
                "x": 1,
                "y": 0,
                "plantId": "95a44ba8-ca62-4755-8080-1ba1af0e6a03"
            },
            {
                "x": 2,
                "y": 0,
                "plantId": "a973c506-da38-4600-a63b-c0ac0a20aee6"
            },
            {
                "x": 3,
                "y": 0,
                "plantId": "a973c506-da38-4600-a63b-c0ac0a20aee6"
            },
            {
                "x": 6,
                "y": 0,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 7,
                "y": 0,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 8,
                "y": 0,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 9,
                "y": 0,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 0,
                "y": 1,
                "plantId": "77012254-e275-4bec-8d81-d24c0e9e03c8"
            },
            {
                "x": 1,
                "y": 1,
                "plantId": "ab0af6c0-091a-4fc4-aae8-dd2160bba8a5"
            },
            {
                "x": 2,
                "y": 1,
                "plantId": "ab0af6c0-091a-4fc4-aae8-dd2160bba8a5"
            },
            {
                "x": 3,
                "y": 1,
                "plantId": "943656ae-e785-4672-9e60-c99f90261b18"
            },
            {
                "x": 4,
                "y": 1,
                "plantId": "943656ae-e785-4672-9e60-c99f90261b18"
            },
            {
                "x": 5,
                "y": 1,
                "plantId": "943656ae-e785-4672-9e60-c99f90261b18"
            },
            {
                "x": 6,
                "y": 1,
                "plantId": "943656ae-e785-4672-9e60-c99f90261b18"
            },
            {
                "x": 7,
                "y": 1,
                "plantId": "9b71d37f-8e6b-49b0-9bd9-cc54927e6f4d"
            },
            {
                "x": 8,
                "y": 1,
                "plantId": "9b71d37f-8e6b-49b0-9bd9-cc54927e6f4d"
            },
            {
                "x": 9,
                "y": 1,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 0,
                "y": 2,
                "plantId": "bdf80cf6-33c6-47b7-900a-e25c90a39098"
            },
            {
                "x": 1,
                "y": 2,
                "plantId": "ab0af6c0-091a-4fc4-aae8-dd2160bba8a5"
            },
            {
                "x": 2,
                "y": 2,
                "plantId": "ab0af6c0-091a-4fc4-aae8-dd2160bba8a5"
            },
            {
                "x": 3,
                "y": 2,
                "plantId": "67478f99-ed47-48c1-93d8-9a6194c60a50"
            },
            {
                "x": 4,
                "y": 2,
                "plantId": "67478f99-ed47-48c1-93d8-9a6194c60a50"
            },
            {
                "x": 5,
                "y": 2,
                "plantId": "4528c2df-d210-42de-857d-a87ee3beb04e"
            },
            {
                "x": 6,
                "y": 2,
                "plantId": "4528c2df-d210-42de-857d-a87ee3beb04e"
            },
            {
                "x": 7,
                "y": 2,
                "plantId": "9b71d37f-8e6b-49b0-9bd9-cc54927e6f4d"
            },
            {
                "x": 8,
                "y": 2,
                "plantId": "9b71d37f-8e6b-49b0-9bd9-cc54927e6f4d"
            },
            {
                "x": 9,
                "y": 2,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 0,
                "y": 3,
                "plantId": "445b68bc-3b7c-4b00-be31-99cf090fca30"
            },
            {
                "x": 1,
                "y": 3,
                "plantId": "2792381e-39d2-4f83-af97-c5c2a08c4ae3"
            },
            {
                "x": 2,
                "y": 3,
                "plantId": "2792381e-39d2-4f83-af97-c5c2a08c4ae3"
            },
            {
                "x": 3,
                "y": 3,
                "plantId": "67478f99-ed47-48c1-93d8-9a6194c60a50"
            },
            {
                "x": 4,
                "y": 3,
                "plantId": "67478f99-ed47-48c1-93d8-9a6194c60a50"
            },
            {
                "x": 5,
                "y": 3,
                "plantId": "4528c2df-d210-42de-857d-a87ee3beb04e"
            },
            {
                "x": 6,
                "y": 3,
                "plantId": "4528c2df-d210-42de-857d-a87ee3beb04e"
            },
            {
                "x": 7,
                "y": 3,
                "plantId": "756e5e53-db57-4662-ac3a-5b0bd14fc183"
            },
            {
                "x": 8,
                "y": 3,
                "plantId": "756e5e53-db57-4662-ac3a-5b0bd14fc183"
            },
            {
                "x": 9,
                "y": 3,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 1,
                "y": 4,
                "plantId": "2792381e-39d2-4f83-af97-c5c2a08c4ae3"
            },
            {
                "x": 2,
                "y": 4,
                "plantId": "2792381e-39d2-4f83-af97-c5c2a08c4ae3"
            },
            {
                "x": 3,
                "y": 4,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 4,
                "y": 4,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 5,
                "y": 4,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 6,
                "y": 4,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 7,
                "y": 4,
                "plantId": "756e5e53-db57-4662-ac3a-5b0bd14fc183"
            },
            {
                "x": 8,
                "y": 4,
                "plantId": "756e5e53-db57-4662-ac3a-5b0bd14fc183"
            },
            {
                "x": 1,
                "y": 5,
                "plantId": "2792381e-39d2-4f83-af97-c5c2a08c4ae3"
            },
            {
                "x": 2,
                "y": 5,
                "plantId": "2792381e-39d2-4f83-af97-c5c2a08c4ae3"
            },
            {
                "x": 3,
                "y": 5,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 4,
                "y": 5,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 5,
                "y": 5,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 6,
                "y": 5,
                "plantId": "d009958c-0a93-46a3-940a-d7645411b97a"
            },
            {
                "x": 7,
                "y": 5,
                "plantId": "95a44ba8-ca62-4755-8080-1ba1af0e6a03"
            },
            {
                "x": 8,
                "y": 5,
                "plantId": "95a44ba8-ca62-4755-8080-1ba1af0e6a03"
            },
            {
                "x": 0,
                "y": 6,
                "plantId": "fd02db89-ba1c-435b-95e2-d84e60972b49"
            },
            {
                "x": 1,
                "y": 6,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 2,
                "y": 6,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 3,
                "y": 6,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 4,
                "y": 6,
                "plantId": "b898212d-5ef7-452e-b9a7-ea0783c56cd1"
            },
            {
                "x": 5,
                "y": 6,
                "plantId": "5c75d72d-68d8-4a4a-95e1-30cf0d2d1066"
            },
            {
                "x": 6,
                "y": 6,
                "plantId": "a2f22a97-1cd9-4d9c-81bb-fcc588e81621"
            },
            {
                "x": 7,
                "y": 6,
                "plantId": "a2f22a97-1cd9-4d9c-81bb-fcc588e81621"
            },
            {
                "x": 8,
                "y": 6,
                "plantId": "a2f22a97-1cd9-4d9c-81bb-fcc588e81621"
            },
            {
                "x": 9,
                "y": 6,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 0,
                "y": 7,
                "plantId": "fd02db89-ba1c-435b-95e2-d84e60972b49"
            },
            {
                "x": 1,
                "y": 7,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 2,
                "y": 7,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 3,
                "y": 7,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 4,
                "y": 7,
                "plantId": "b898212d-5ef7-452e-b9a7-ea0783c56cd1"
            },
            {
                "x": 5,
                "y": 7,
                "plantId": "5c75d72d-68d8-4a4a-95e1-30cf0d2d1066"
            },
            {
                "x": 6,
                "y": 7,
                "plantId": "34327cb7-e911-4c93-90bf-9fa8a34c4654"
            },
            {
                "x": 7,
                "y": 7,
                "plantId": "34327cb7-e911-4c93-90bf-9fa8a34c4654"
            },
            {
                "x": 8,
                "y": 7,
                "plantId": "34327cb7-e911-4c93-90bf-9fa8a34c4654"
            },
            {
                "x": 9,
                "y": 7,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 0,
                "y": 8,
                "plantId": "fd02db89-ba1c-435b-95e2-d84e60972b49"
            },
            {
                "x": 1,
                "y": 8,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 2,
                "y": 8,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 3,
                "y": 8,
                "plantId": "e032d258-3f8d-4709-b0ac-941a39573f6f"
            },
            {
                "x": 4,
                "y": 8,
                "plantId": "b898212d-5ef7-452e-b9a7-ea0783c56cd1"
            },
            {
                "x": 5,
                "y": 8,
                "plantId": "5c75d72d-68d8-4a4a-95e1-30cf0d2d1066"
            },
            {
                "x": 6,
                "y": 8,
                "plantId": "34327cb7-e911-4c93-90bf-9fa8a34c4654"
            },
            {
                "x": 7,
                "y": 8,
                "plantId": "34327cb7-e911-4c93-90bf-9fa8a34c4654"
            },
            {
                "x": 8,
                "y": 8,
                "plantId": "34327cb7-e911-4c93-90bf-9fa8a34c4654"
            },
            {
                "x": 9,
                "y": 8,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 0,
                "y": 9,
                "plantId": "fd02db89-ba1c-435b-95e2-d84e60972b49"
            },
            {
                "x": 1,
                "y": 9,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 2,
                "y": 9,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 3,
                "y": 9,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 6,
                "y": 9,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 7,
                "y": 9,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 8,
                "y": 9,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            },
            {
                "x": 9,
                "y": 9,
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2"
            }
        ],
        "plants": [
            {
                "id": "ab0af6c0-091a-4fc4-aae8-dd2160bba8a5",
                "name": "Arugula",
                "color": "#587e1b",
                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": -4,
                "plantsPerSq": 9,
                "transplantWeeks": 0
            },
            {
                "id": "a2f22a97-1cd9-4d9c-81bb-fcc588e81621",
                "name": "Basil",
                "color": "#4aba86",
                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -6,
                "outdoorWeeks": 1,
                "plantsPerSq": 2,
                "transplantWeeks": 1
            },
            {
                "id": "67478f99-ed47-48c1-93d8-9a6194c60a50",
                "name": "Bell Peppers",
                "color": "#ff1900",
                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -10,
                "outdoorWeeks": 0,
                "plantsPerSq": 0.25,
                "transplantWeeks": 2
            },
            {
                "id": "e032d258-3f8d-4709-b0ac-941a39573f6f",
                "name": "Cantaloupe",
                "color": "#e7c274",
                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": 5,
                "plantsPerSq": 0.33,
                "transplantWeeks": 0
            },
            {
                "id": "d009958c-0a93-46a3-940a-d7645411b97a",
                "name": "Carrots",
                "color": "#cc7905",
                "seedsPerPlant": 1,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": 0,
                "plantsPerSq": 10,
                "transplantWeeks": 0
            },
            {
                "id": "bdf80cf6-33c6-47b7-900a-e25c90a39098",
                "name": "Chives",
                "color": "#0e3e23",
                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": -6,
                "plantsPerSq": 1,
                "transplantWeeks": 0
            },
            {
                "id": "445b68bc-3b7c-4b00-be31-99cf090fca30",
                "name": "Cilantro",
                "color": "#667d1c",

                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": -4,
                "plantsPerSq": 1,
                "transplantWeeks": 0
            },
            {
                "id": "fd02db89-ba1c-435b-95e2-d84e60972b49",
                "name": "Dill",
                "color": "#d8d113",

                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -4,
                "outdoorWeeks": 0,
                "plantsPerSq": 1,
                "transplantWeeks": 0
            },
            {
                "id": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2",
                "name": "Flowers",
                "color": "#e01fc7",

                "seedsPerPlant": 6,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": 0,
                "plantsPerSq": 6,
                "transplantWeeks": 0
            },
            {
                "id": "95a44ba8-ca62-4755-8080-1ba1af0e6a03",
                "name": "Green Onions",
                "color": "#397a01",

                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -10,
                "outdoorWeeks": 0,
                "plantsPerSq": 4,
                "transplantWeeks": 0
            },
            {
                "id": "4528c2df-d210-42de-857d-a87ee3beb04e",
                "name": "Jalapeños",
                "color": "#1c5c0f",

                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -10,
                "outdoorWeeks": 0,
                "plantsPerSq": 0.25,
                "transplantWeeks": 2
            },
            {
                "id": "2792381e-39d2-4f83-af97-c5c2a08c4ae3",
                "name": "Kale",
                "color": "#016a68",

                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -6,
                "outdoorWeeks": 0,
                "plantsPerSq": 1,
                "transplantWeeks": 1
            },
            {
                "id": "b898212d-5ef7-452e-b9a7-ea0783c56cd1",
                "name": "Lettuce",
                "color": "#90f54d",

                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": -4,
                "plantsPerSq": 4,
                "transplantWeeks": 0
            },
            {
                "id": "943656ae-e785-4672-9e60-c99f90261b18",
                "name": "Onions",
                "color": "#d3c373",

                "seedsPerPlant": 1,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": -2,
                "plantsPerSq": 4,
                "transplantWeeks": 0
            },
            {
                "id": "1045f900-4c97-4bf3-a06f-485a2c7fe2d9",
                "name": "Rosemary",
                "color": "#158424",

                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -8,
                "outdoorWeeks": 0,
                "plantsPerSq": 1,
                "transplantWeeks": 1
            },
            {
                "id": "77012254-e275-4bec-8d81-d24c0e9e03c8",
                "name": "Sage",
                "color": "#595e1c",

                "seedsPerPlant": 3,
                "sow": "startingIndoors",
                "indoorWeeks": -8,
                "outdoorWeeks": 0,
                "plantsPerSq": 1,
                "transplantWeeks": 1
            },
            {
                "id": "5c75d72d-68d8-4a4a-95e1-30cf0d2d1066",
                "name": "Spinach",
                "color": "#4a6f2a",

                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": -8,
                "plantsPerSq": 4,
                "transplantWeeks": 0
            },
            {
                "id": "a973c506-da38-4600-a63b-c0ac0a20aee6",
                "name": "Thyme",
                "color": "#6f7623",

                "seedsPerPlant": 1,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": 0,
                "plantsPerSq": 0.5,
                "transplantWeeks": 0
            },
            {
                "id": "34327cb7-e911-4c93-90bf-9fa8a34c4654",
                "name": "Tomatoes",
                "color": "#8b1313",

                "seedsPerPlant": 1,
                "sow": "transplanting",
                "indoorWeeks": 0,
                "outdoorWeeks": 0,
                "plantsPerSq": 0.33,
                "transplantWeeks": 2
            },
            {
                "id": "9b71d37f-8e6b-49b0-9bd9-cc54927e6f4d",
                "name": "Yellow Squash",
                "color": "#ced11a",

                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": 5,
                "plantsPerSq": 0.25,
                "transplantWeeks": 0
            },
            {
                "id": "756e5e53-db57-4662-ac3a-5b0bd14fc183",
                "name": "Zucchini",
                "color": "#83a509",

                "seedsPerPlant": 3,
                "sow": "direct",
                "indoorWeeks": 0,
                "outdoorWeeks": 5,
                "plantsPerSq": 0.25,
                "transplantWeeks": 0
            }
        ],
        "created": Date.now(),
        "version": CURRENT_GARDEN_VERSION,
        "shopping": [
            {
                "plantId": "a2f22a97-1cd9-4d9c-81bb-fcc588e81621",
                "created": Date.now()
            },
            {
                "plantId": "67478f99-ed47-48c1-93d8-9a6194c60a50",
                "created": Date.now()
            },
            {
                "plantId": "bdf80cf6-33c6-47b7-900a-e25c90a39098",
                "created": Date.now()
            },
            {
                "plantId": "445b68bc-3b7c-4b00-be31-99cf090fca30",
                "created": Date.now()
            },
            {
                "plantId": "7e3cfb2e-d5af-4989-a083-c36e457b9845",
                "created": Date.now()
            },
            {
                "plantId": "fd02db89-ba1c-435b-95e2-d84e60972b49",
                "created": Date.now()
            },
            {
                "plantId": "1a0219de-7cfa-4e80-8e56-3e30d20b81e2",
                "created": Date.now()
            },
            {
                "plantId": "4528c2df-d210-42de-857d-a87ee3beb04e",
                "created": Date.now()
            },
            {
                "plantId": "1045f900-4c97-4bf3-a06f-485a2c7fe2d9",
                "created": Date.now()
            },
            {
                "plantId": "77012254-e275-4bec-8d81-d24c0e9e03c8",
                "created": Date.now()
            },
            {
                "plantId": "a973c506-da38-4600-a63b-c0ac0a20aee6",
                "created": Date.now()
            },
            {
                "plantId": "3a4fff8c-4d1d-4b66-8f2a-61b170ba52d3",
                "created": Date.now()
            }
        ],
        "plantings": [],
        "syncVersion": 0
    };

    const plantIdMap: { [key: string]: string } = {};

    garden.plants.forEach(plant => {
        const newId = uuidv4();
        plantIdMap[plant.id] = newId;
        plant.id = newId;
    });

    garden.units.forEach(unit => {
        if (unit.plantId) {
            unit.plantId = plantIdMap[unit.plantId];
        }
    });

    garden.shopping.forEach(shopping => {
        shopping.plantId = plantIdMap[shopping.plantId];
    });

    return garden;
}

export default sampleGarden;