import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition, Dialog } from "@headlessui/react";
import AppButton from "components/common/app_button/AppButton";
import SolidLogo from "components/common/logo/SolidLogo";
import { db } from "index";
import { Fragment, useCallback, useEffect, useState } from "react";

const LaunchModal = () => {
    const [show, setShow] = useState<boolean>(false);
    const [dontShowAnymore, setDontShowAnymore] = useState<boolean>(false);

    useEffect(() => {
        const settings = db.getUserSettings();

        if (!settings.launchShown) {
            setShow(_ => true);
            settings.launchShown = true;
            db.updateUserSettings(settings);
        }
    }, []);

    const onClose = useCallback(() => {
        if (dontShowAnymore) {
            const settings = db.getUserSettings();
            settings.hideAllHelp = true;
            db.updateUserSettings(settings);
        }

        setShow(_ => false);
    }, [dontShowAnymore]);

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-button-primary-bg text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:w-full sm:max-w-xl sm:p-6">
                                <div>
                                    <h1 className="text-2xl font-bold text-center block">Welcome to Squardener!</h1>
                                    <div className="text-center my-4">
                                        <SolidLogo className="w-20 h-20 inline-block fill-white" />
                                    </div>
                                    <p className="mt-2 text-center text-white opacity-90">Plan and plant your very own garden with ease using Squardener's simple square-unit planning tools, calendar, and shopping lists.</p>
                                    <p className="my-4 text-center text-white text-lg font-bold">Gardening should be easy, fun, and rewarding!</p>
                                    <div className="text-center text-white opacity-90 font-bold underline leading-tight mb-4">
                                        <a href="https://web.dev/learn/pwa/installation" target="_blank">Click here to learn how to install Squardener on your computer or iOS/Android device!</a>
                                    </div>
                                    <AppButton buttonType="outline" className="w-full flex flex-row items-center justify-center !ring-white !text-white" onClick={onClose}><span className="mr-2">Get Started</span><FontAwesomeIcon icon={faChevronRight} /></AppButton>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

export default LaunchModal;