import { PlusIcon } from "@heroicons/react/24/outline";
import AppButton from "components/common/app_button/AppButton";
import SlidingPanel, { PanelAction } from "components/sliding_panel/SlidingPanel";
import GardenPlant from "data/models/GardenPlant";
import { useState } from "react";
import { EditMode } from "./utilities/Types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPencil } from "@fortawesome/pro-light-svg-icons";
import EditPlantPanel from "./EditPlantPanel";
import Divider from "components/common/divider/Divider";

type PlantSelectorPanelProps = {
    show: boolean;
    plants: GardenPlant[];
    onClose: () => void;
    onCurrentPlantChanged: (plant: GardenPlant | undefined | null) => void;
    onModeChanged: (mode: EditMode) => void;
    onShowNewPlantModal: (show: boolean) => void;
};

export default function PlantSelectorPanel(props: PlantSelectorPanelProps) {
    const { plants, onCurrentPlantChanged, onModeChanged, onShowNewPlantModal } = props;
    const [editingPlant, setEditingPlant] = useState<GardenPlant | null>(null);

    const handleAction = (_: PanelAction) => {
        props.onClose();
    };

    return <>
        <SlidingPanel
            show={props.show && !editingPlant}
            onClose={props.onClose}
            title="Select a plant"
            subtitle="Choose which plant you'd like to place or create a new plant."
            actions={[
                { id: "cancel", text: "Close" },
            ]}
            onAction={handleAction}
        >
            <div className="px-4">
                <div className="p-3">
                    <div className="hover:bg-main-background-secondary py-1 pr-1">
                        <div className="flex flex-row">
                            <button
                                type="button"
                                className="flex-1 inline-flex flex-row space-x-1 items-center transition-colors duration-default rounded-md py-2 px-3 text-sm font-semibold disabled:opacity-20 bg-transparent  text-main-background-tertiary"
                                onClick={() => {
                                    onCurrentPlantChanged(undefined);
                                    onModeChanged("shaping");
                                    props.onClose();
                                }}
                            >
                                <div
                                    className="min-w-4 mr-1 w-4 flex-shrink-0"
                                // style={{ borderWidth: '1px', borderColor: "rgba(0,0,0,0.2)" }}
                                >
                                    <FontAwesomeIcon icon={faBan} className="opacity-50" />
                                </div>
                                <div
                                    className="flex-auto truncate text-left"
                                    title="No selection"
                                >
                                    No selection
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="hover:bg-main-background-secondary py-1 pr-1">
                        <div className="flex flex-row">
                            <button
                                type="button"
                                className="flex-1 inline-flex flex-row space-x-1 items-center transition-colors duration-default rounded-md py-2 px-3 text-sm font-semibold disabled:opacity-20 bg-transparent  text-main-background-tertiary"
                                onClick={() => {
                                    onCurrentPlantChanged(null);
                                    onModeChanged("shaping");
                                    props.onClose();
                                }}
                            >
                                <div
                                    className="min-w-4 mr-1 h-4 w-4 flex-shrink-0 rounded-full"
                                    style={{ backgroundColor: "#b8a382" }}
                                ></div>
                                <div
                                    className="flex-auto truncate text-left"
                                    title="Soil"
                                >
                                    Soil
                                </div>
                            </button>
                        </div>
                    </div>
                    <Divider className="my-5" />
                    {plants.length === 0 && <>
                        <div className="text-center p-4 rounded-md">
                            <h1 className="font-semibold mb-2">You don't have any plants, yet!</h1>
                            <p className="mb-2">Create some plants to start adding them to your garden.</p>
                            <AppButton
                                buttonType="primary"
                                className="mt-2 w-full flex items-center gap-x-1 "
                                onClick={() => onShowNewPlantModal(true)}
                            >
                                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Add new plant!
                            </AppButton>
                        </div>
                    </>}
                    {plants.length > 0 && <>
                        <div>
                            {plants.sort((a, b) => a.name.localeCompare(b.name)).map((p) => (
                                <div key={p.id} className="hover:bg-main-background-secondary border-b border-b-slate-100 last-of-type:border-b-0 py-1 pr-1">
                                    <div className="flex flex-row">
                                        <button
                                            type="button"
                                            className="flex-1 inline-flex flex-row space-x-1 items-center transition-colors duration-default rounded-md py-2 px-3 text-sm font-semibold disabled:opacity-20 bg-transparent  text-main-background-tertiary"
                                            onClick={() => {
                                                onCurrentPlantChanged(p);
                                                onModeChanged("planting");
                                                props.onClose();
                                            }}
                                        >
                                            <div
                                                className="min-w-4 mr-1 h-4 w-4 flex-shrink-0 rounded-full"
                                                style={{ backgroundColor: p.color }}
                                            ></div>
                                            <div
                                                className="flex-auto truncate text-left"
                                                title={p.name}
                                            >
                                                {p.name}
                                            </div>
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-2 inline-flex flex-row  space-x-1 items-center transition-colors duration-default rounded-md py-2 px-3 text-sm font-semibold disabled:opacity-20 bg-transparent text-main-background-tertiary hover:bg-button-outline-bg"
                                            onClick={() => setEditingPlant(p)}
                                        >
                                            <FontAwesomeIcon icon={faPencil} /> <span>Edit</span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <div>
                                <div>
                                    <AppButton
                                        buttonType="primary"
                                        className="mt-2 w-full flex items-center gap-x-1 "
                                        onClick={() => onShowNewPlantModal(true)}
                                    >
                                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        Add new plant...
                                    </AppButton>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </SlidingPanel>
        <EditPlantPanel
            plant={editingPlant!}
            show={editingPlant != null}
            onClose={() => setEditingPlant(null)}
        />
    </>;
}
