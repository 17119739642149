import { v4 as uuidv4 } from "uuid";
import { DBSchema, IDBPDatabase, openDB } from "idb";
import Garden from "../models/Garden";
import UserSettings from "data/models/UserSettings";
import { migrations } from "./versions";
import { copyObject } from "utilities/Objects";

type InitState = "initializing" | "initialized" | "error" | "blocked";

interface GardenSchema extends DBSchema {
    gardens: { value: Garden, key: string };
}

export const CURRENT_GARDEN_VERSION = 3;

export default class LocalDb {
    private db: IDBPDatabase<GardenSchema> | null = null;
    private initState: InitState = "initializing";

    getState() {
        return this.initState;
    }

    async initialize() {
        const self = this;

        try {
            this.db = await openDB<GardenSchema>("gardens-db", 1, {
                blocked() {
                    self.initState = "blocked";
                },
                blocking() {
                    self.initState = "blocked";
                },
                terminated() {
                    self.initState = "error";
                },
                upgrade(db) {
                    db.createObjectStore("gardens", { keyPath: "id" });
                }
            });
            self.initState = "initialized";
            (window as any).saveGarden = this.saveGarden.bind(this);
            (window as any).getGarden = this.getGarden.bind(this);
        }
        catch (e) {
            console.error(e);
            this.initState = "error";
        }
    }

    initialized() {
        return this.initState === "initialized";
    }

    assertInitialized() {
        if (!this.initialized() || this.db === null) {
            throw new Error("Database not initialized");
        }
    }

    async getGardens() {
        this.assertInitialized();

        const gardens = (await this.db?.getAll("gardens"));

        if (gardens) {
            for (let i = 0; i < gardens.length; i++) {
                gardens[i] = await this.migrateGarden(gardens[i]);
            }
        }

        return gardens?.sort((a, b) => b.created - a.created) ?? [];
    }

    async getGarden(id?: string) {
        this.assertInitialized();

        if (!id) {
            return undefined;
        }

        let garden = await this.db?.get("gardens", id);

        if (garden) {
            garden = await this.migrateGarden(garden);
        }

        return garden;
    }

    private async migrateGarden(garden: Garden): Promise<Garden> {
        if (garden.version < CURRENT_GARDEN_VERSION) {
            let nextMigration = migrations.find(m => m.version === garden.version + 1);
            let migratedGarden = copyObject(garden);

            while (nextMigration && nextMigration.version <= CURRENT_GARDEN_VERSION) {
                if (nextMigration.migrate) {
                    migratedGarden = nextMigration.migrate(migratedGarden);
                }
                nextMigration = migrations.find(m => m.version === migratedGarden.version + 1);
            }

            await this.saveGarden(migratedGarden as Garden);
            return migratedGarden as Garden;
        }

        return garden;
    };

    async createGarden(minDetails?: { name?: string, seasonStart?: string, seasonEnd?: string, rows?: number, columns?: number }) {
        const id = uuidv4();
        const garden: Garden = {
            id: id,
            version: CURRENT_GARDEN_VERSION,
            syncVersion: 0,
            details: {
                name: minDetails?.name ?? "New garden",
                seasonStart: minDetails?.seasonStart ?? "",
                seasonEnd: minDetails?.seasonEnd ?? "",
                rows: minDetails?.rows ?? 4,
                columns: minDetails?.columns ?? 4
            },
            units: [],
            plants: [],
            shopping: [],
            plantings: [],
            created: Date.now()
        };

        await this.saveGarden(garden);

        return garden;
    }

    async importGarden(garden: Garden) {
        garden.syncVersion = 0;
        await this.saveGarden(garden);
        return garden;
    }

    async saveGarden(garden: Garden) {
        this.assertInitialized();

        const dbGarden = await this.db?.get("gardens", garden.id);
        const syncVersion = (dbGarden?.syncVersion ?? 0) + 1;
        garden.syncVersion = syncVersion;
        await this.db?.put("gardens", garden);
    }

    async deleteGarden(id: string) {
        this.assertInitialized();

        await this.db?.delete("gardens", id);
    }

    getUserSettings(): UserSettings {
        let settingsData = window.localStorage.getItem("user-settings");

        if (!settingsData) {
            settingsData = JSON.stringify({
                launchShown: false,
                hideAllHelp: false,
                helpShownIds: []
            });
            window.localStorage.setItem("user-settings", settingsData);
        }

        return JSON.parse(settingsData) as UserSettings;
    }

    updateUserSettings(settings: UserSettings) {
        window.localStorage.setItem("user-settings", JSON.stringify(settings));
    }
}
