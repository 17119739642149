import Garden from "data/models/Garden";
import { createContext } from "react";

export type ScheduleContextType = {
  showMap?: boolean;
  setShowMap?: (show: boolean) => void;
  plantIdForMap?: string;
  setPlantIdForMap?: (id: string) => void;
};
export const ScheduleContext = createContext<ScheduleContextType>({
  showMap: false,
  setShowMap: undefined,
  plantIdForMap: undefined,
  setPlantIdForMap: undefined,
});
