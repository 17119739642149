import { GardenContext } from "pages/garden/GardenContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./plantsTable.css";
import GardenPlant from "data/models/GardenPlant";
import Garden from "data/models/Garden";
import AppButton from "components/common/app_button/AppButton";
import { copyObject } from "utilities/Objects";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlantRow from "./PlantRow";
import { useNavigate } from "react-router-dom";
import { defaultIfNaN } from "utilities/Numbers";
import Title from "components/common/title/Title";


const PlantsPage = () => {
    const ctx = useContext(GardenContext);

    const [currentGarden, setCurrentGarden] = useState<Garden | undefined>(copyObject(ctx.garden));
    const plants = useMemo(() => currentGarden?.plants.sort((a, b) => a.name.localeCompare(b.name)) || [], [currentGarden]);
    const unsavedChanges = useMemo(() => {
        const gardenJson = JSON.stringify(ctx.garden?.plants ?? []);
        const localJson = JSON.stringify(currentGarden?.plants ?? []);
        return gardenJson !== localJson;
    }, [ctx.garden, currentGarden]);
    const navigate = useNavigate();

    useEffect(() => {
        ctx.setTitle?.("Plant Editor");
    }, []);

    const updatePlant = useCallback((plant: GardenPlant) => {
        const idx = currentGarden!.plants.findIndex(p => p.id === plant.id);
        currentGarden!.plants[idx] = plant;
        setCurrentGarden(_ => copyObject(currentGarden));
    }, [currentGarden, ctx.garden]);

    const saveChanges = useCallback(() => {
        if (!unsavedChanges) {
            return;
        }

        const newGarden = copyObject(currentGarden!);
        for (let i = 0; i < newGarden.plants.length; i++) {
            const plant = newGarden.plants[i];
            plant.seedsPerPlant = defaultIfNaN(plant.seedsPerPlant, 1);
            plant.indoorWeeks = defaultIfNaN(plant.indoorWeeks, 0);
            plant.outdoorWeeks = defaultIfNaN(plant.outdoorWeeks, 0);
            plant.transplantWeeks = defaultIfNaN(plant.transplantWeeks, 0);
        }
        ctx.onGardenUpdated?.(newGarden);
    }, [unsavedChanges, currentGarden, ctx.onGardenUpdated, ctx.garden]);

    useEffect(() => {
        setCurrentGarden(_ => copyObject(ctx.garden));
    }, [ctx.garden]);

    return (
        <div className="flex flex-col h-full w-full">
            <Title className="text-2xl mb-2">Plant Editor</Title>
            {plants.length === 0 && <div className="flex flex-col items-center justify-center pt-8">
                <div className="text-center p-4 rounded-md">
                    <h1 className="font-semibold mb-2">You don't have any plants, yet!</h1>
                    <p className="mb-2">Create some plants from the Garden Planner.</p>
                    <AppButton buttonType="primary" onClick={() => navigate("../planner")}>Get planning now!</AppButton>
                </div>
            </div>}
            {plants.length > 0 && <>
                <p className="mb-2 text-sm opacity-tertiary">Use this scrolling plant editor table to easily update your garden's planting details and dates.</p>
                <div className="overflow-auto flex-1 rounded-md">
                    {plants.length > 0 && <table id="plantEditor">
                        <thead>
                            <tr>
                                <th>Plant</th>
                                <th>Sowing method</th>
                                <th>Plants per square unit</th>
                                <th>Seeds per plant</th>
                                <th>
                                    <p>Outdoor planting</p>
                                    <p className="font-normal text-xxs opacity-tertiary">Weeks from season start</p>
                                </th>
                                <th>
                                    <p>Indoor planting</p>
                                    <p className="font-normal text-xxs opacity-tertiary">Weeks from season start</p>
                                </th>
                                <th>
                                    <p>Transplanting</p>
                                    <p className="font-normal text-xxs opacity-tertiary">Weeks from season start</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {plants.map((plant, idx) => <PlantRow key={plant.id} garden={currentGarden!} plant={plant} updatePlant={updatePlant} />)}
                        </tbody>
                    </table>}
                </div>
                <div className="pt-4">
                    <AppButton disabled={!unsavedChanges} buttonType="primary" onClick={saveChanges}><FontAwesomeIcon icon={faSave} className="mr-1" /> Save changes</AppButton>
                </div>
            </>}
        </div>
    )
}

export default PlantsPage;