import Divider from "components/common/divider/Divider";
import SlidingPanel, {
  PanelAction,
} from "components/sliding_panel/SlidingPanel";
import GardenPlant from "data/models/GardenPlant";
import SowingMethod from "data/models/SowingMethod";
import { GardenContext } from "pages/garden/GardenContext";
import { useContext, useEffect, useRef, useState } from "react";
import { randomPlantColor } from "utilities/Colors";
import { weeksFromDate } from "utilities/Dates";
import { copyObject } from "utilities/Objects";
import { PlannerContext } from "./PlannerContext";
import PlantPerSqInput from "components/common/plant_per_sqft_input/PlantPerSqFtInput";
import AppButton from "components/common/app_button/AppButton";
import { defaultIfNaN, parseNumber } from "utilities/Numbers";

type EditPlantPanelProps = {
  plant: GardenPlant;
  show: boolean;
  onClose: () => void;
};

export default function EditPlantPanel(props: EditPlantPanelProps) {
  const ctx = useContext(GardenContext);
  const plannerCtx = useContext(PlannerContext);
  const [name, setName] = useState("New plant");
  const [sowingMethod, setSowingMethod] = useState<SowingMethod>("direct");
  const [color, setColor] = useState(randomPlantColor());
  const colorRef = useRef<HTMLInputElement>(null);
  const [indoorWeeksRelativeToFrost, setIndoorWeeksRelativeToFrost] =
    useState(0);
  const [
    outdoorPlantingRelativeToFrostInWeeks,
    setOutdoorPlantingRelativeToFrostInWeeks,
  ] = useState(0);
  const [
    transplantOutdoorsRelativeToFrostInWeeks,
    setTransplantOutdoorsRelativeToFrostInWeeks,
  ] = useState(0);
  const [seedsPerPlant, setSeedsPerPlant] = useState(1);
  const [plantsPerSquare, setPlantsPerSquare] = useState(1);

  const updatePlant = () => {
    const plant: GardenPlant = {
      id: props.plant.id,
      name: name,
      color: color,
      sow: sowingMethod,
      indoorWeeks: defaultIfNaN(indoorWeeksRelativeToFrost, 0),
      outdoorWeeks: defaultIfNaN(outdoorPlantingRelativeToFrostInWeeks, 0),
      transplantWeeks: defaultIfNaN(transplantOutdoorsRelativeToFrostInWeeks, 0),
      seedsPerPlant: defaultIfNaN(seedsPerPlant, 1),
      plantsPerSq: plantsPerSquare,
    }

    const garden = copyObject(ctx.garden!);

    const idx = garden.plants.findIndex((p) => p.id === plant.id);
    garden.plants[idx] = plant;
    ctx.onGardenUpdated?.(garden);
    if (plannerCtx.currentPlant?.id === plant.id) {
      plannerCtx.setCurrentPlant?.(plant);
    }
    props.onClose();
  }

  const deletePlant = () => {
    const garden = copyObject(ctx.garden!);
    const idx = garden.plants.findIndex((p) => p.id === props.plant.id);
    garden.plants.splice(idx, 1);
    garden.units.forEach(u => {
      if (u.plantId === props.plant.id) {
        u.plantId = undefined;
      }
    });
    ctx.onGardenUpdated?.(garden);
    if (plannerCtx.currentPlant?.id === props.plant.id) {
      plannerCtx.setCurrentPlant?.(null);
    }
    props.onClose();
  };

  useEffect(() => {
    if (props.show) {
      setName(props.plant.name);
      setColor(props.plant.color);
      setSowingMethod(props.plant.sow);
      setIndoorWeeksRelativeToFrost(props.plant.indoorWeeks);
      setOutdoorPlantingRelativeToFrostInWeeks(props.plant.outdoorWeeks);
      setTransplantOutdoorsRelativeToFrostInWeeks(props.plant.transplantWeeks);
      setSeedsPerPlant(props.plant.seedsPerPlant);
      setPlantsPerSquare(props.plant.plantsPerSq);
    }
  }, [props.show]);

  const handleAction = (action: PanelAction) => {
    if (action.id === "cancel") {
      props.onClose();
    } else if (action.id === "add") {
      updatePlant();
    }
  };

  const anchor = "start";

  return (
    <SlidingPanel
      show={props.show}
      onClose={props.onClose}
      title="Edit your plant"
      subtitle="Make changes for your garden plant."
      actions={[
        { id: "cancel", text: "Cancel" },
        { id: "add", text: "Save", primary: true },
      ]}
      onAction={handleAction}
    >
      <div className="px-4">
        <div className="space-y-6 pt-6">
          <div>
            <label
              htmlFor="plant-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Plant name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="plant-name"
                id="plant-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Unit Color
            </label>
            <div className="mt-2">
              <input
                name="color"
                id="color"
                type="color"
                value={color}
                ref={colorRef}
                className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                onChange={(e) => setColor(e.target.value)}

              />
            </div>
          </div>
        </div>
        <Divider className="my-5" />
        <div>
          <h1 className="mb-1 font-semibold text-lg">Planting Details</h1>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              How are you starting this plant?
            </label>
            <div className="mt-2">
              <div className="mb-1 flex flex-row items-center rounded-sm p-2">
                <input
                  name="sowing-method"
                  id="sow-indoor"
                  type="radio"
                  className="cursor-pointer"
                  onChange={() => setSowingMethod("startingIndoors")}
                  checked={sowingMethod === "startingIndoors"}
                />
                <label
                  htmlFor="sow-indoor"
                  className="ml-2 flex-1 cursor-pointer text-sm"
                >
                  Starting from seed indoors
                </label>
              </div>
              <div className="mb-1 flex flex-row items-center rounded-sm p-2">
                <input
                  name="sowing-method"
                  id="sow-outdoor"
                  type="radio"
                  className="cursor-pointer"
                  onChange={() => setSowingMethod("direct")}
                  checked={sowingMethod === "direct"}
                />
                <label
                  htmlFor="sow-outdoor"
                  className="ml-2 flex-1 cursor-pointer text-sm"
                >
                  Starting from seed outdoors
                </label>
              </div>
              <div className="mb-1 flex flex-row items-center rounded-sm p-2">
                <input
                  name="sowing-method"
                  id="transplant-outdoor"
                  type="radio"
                  className="cursor-pointer"
                  onChange={() => setSowingMethod("transplanting")}
                  checked={sowingMethod === "transplanting"}
                />
                <label
                  htmlFor="transplant-outdoor"
                  className="ml-2 flex-1 cursor-pointer text-sm"
                >
                  Planting a grown plant outdoors
                </label>
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="columns"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                How many plants will you plant per square unit?
              </label>
              <div className="mt-2">
                <PlantPerSqInput initialValue={plantsPerSquare} onChange={v => setPlantsPerSquare(v)} />
              </div>
            </div>
          </div>
          {(sowingMethod === "startingIndoors" || sowingMethod === "direct") && <>
            <div className="mt-2">
              <label
                htmlFor="seedsPer"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                How many seeds will you plant per plant?
              </label>
              <div className="mt-2">
                <input
                  name="seedsPer"
                  id="seedsPer"
                  type="number"
                  value={isNaN(seedsPerPlant) ? "" : seedsPerPlant}
                  onChange={(e) => setSeedsPerPlant(e.target.valueAsNumber)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </>}
          {sowingMethod === "startingIndoors" && (
            <>
              <div className="mt-4">
                <label
                  htmlFor="plantIndoorsWeeks"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Weeks from season {anchor} to start indoors
                  <span className=" block rounded-sm bg-gray-100 p-2 text-xs font-light text-gray-600">
                    <span className="mb-2 block font-semibold">Examples</span>
                    <ul className="list-none pl-2">
                      <li>
                        <span className="inline-block w-4 text-center font-semibold">
                          -4
                        </span>{" "}
                        = 4 weeks before season {anchor} date
                      </li>
                      <li>
                        <span className="inline-block w-4 text-center font-semibold">
                          0
                        </span>{" "}
                        = same as season {anchor} date
                      </li>
                      <li>
                        <span className="inline-block w-4 text-center font-semibold">
                          4
                        </span>{" "}
                        = 4 weeks after season {anchor} date
                      </li>
                    </ul>
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    name="plantIndoorsWeeks"
                    id="plantIndoorsWeeks"
                    type="number"
                    value={isNaN(indoorWeeksRelativeToFrost) ? "" : indoorWeeksRelativeToFrost}
                    onChange={(e) => setIndoorWeeksRelativeToFrost(e.target.valueAsNumber)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <span className="mt-1 block text-right text-xs font-light text-gray-500">
                    Approximate date:{" "}
                    {weeksFromDate(ctx.garden!.details.seasonStart!, indoorWeeksRelativeToFrost).toFormat("DDD")}
                  </span>
                </div>
              </div>
            </>
          )}
          {(sowingMethod === "startingIndoors" || sowingMethod === "transplanting") && (
            <>
              <div className="mt-4">
                <label
                  htmlFor="transplantOutdoorsWeeks"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Weeks from season {anchor} to transplant outdoors
                  <span className=" block rounded-sm bg-gray-100 p-2 text-xs font-light text-gray-600">
                    <span className="mb-2 block font-semibold">Examples</span>
                    <ul className="list-none pl-2">
                      <li>
                        <span className="inline-block w-4 text-center font-semibold">
                          -4
                        </span>{" "}
                        = 4 weeks before season {anchor} date
                      </li>
                      <li>
                        <span className="inline-block w-4 text-center font-semibold">
                          0
                        </span>{" "}
                        = same as season {anchor} date
                      </li>
                      <li>
                        <span className="inline-block w-4 text-center font-semibold">
                          4
                        </span>{" "}
                        = 4 weeks after season {anchor} date
                      </li>
                    </ul>
                  </span>
                </label>
                <div className="mt-2">
                  <input
                    name="transplantOutdoorsWeeks"
                    id="transplantOutdoorsWeeks"
                    type="number"
                    value={isNaN(transplantOutdoorsRelativeToFrostInWeeks) ? "" : transplantOutdoorsRelativeToFrostInWeeks}
                    onChange={(e) => setTransplantOutdoorsRelativeToFrostInWeeks(e.target.valueAsNumber)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <span className="mt-1 block text-right text-xs font-light text-gray-500">
                    Approximate date:{" "}
                    {weeksFromDate(ctx.garden!.details.seasonStart!,
                      transplantOutdoorsRelativeToFrostInWeeks
                    ).toFormat("DDD")}
                  </span>
                </div>
              </div>
            </>
          )}
          {sowingMethod === "direct" && (
            <div className="mt-4">
              <label
                htmlFor="plantOutdoorsWeeks"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Weeks from season {anchor} to sow outdoors
                <span className=" block rounded-sm bg-gray-100 p-2 text-xs font-light text-gray-600">
                  <span className="mb-2 block font-semibold">Examples</span>
                  <ul className="list-none pl-2">
                    <li>
                      <span className="inline-block w-4 text-center font-semibold">
                        -4
                      </span>{" "}
                      = 4 weeks before season {anchor} date
                    </li>
                    <li>
                      <span className="inline-block w-4 text-center font-semibold">
                        0
                      </span>{" "}
                      = same as season {anchor} date
                    </li>
                    <li>
                      <span className="inline-block w-4 text-center font-semibold">
                        4
                      </span>{" "}
                      = 4 weeks after season {anchor} date
                    </li>
                  </ul>
                </span>
              </label>
              <div className="mt-2">
                <input
                  name="plantOutdoorsWeeks"
                  id="plantOutdoorsWeeks"
                  type="number"
                  value={isNaN(outdoorPlantingRelativeToFrostInWeeks) ? "" : outdoorPlantingRelativeToFrostInWeeks}
                  onChange={(e) => setOutdoorPlantingRelativeToFrostInWeeks(e.target.valueAsNumber)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <span className="mt-1 block text-right text-xs font-light text-gray-500">
                  Approximate date:{" "}
                  {weeksFromDate(ctx.garden!.details.seasonStart!,
                    outdoorPlantingRelativeToFrostInWeeks
                  ).toFormat("DDD")}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <h1 className="mb-1 font-semibold text-lg">Delete plant</h1>
          <AppButton buttonType="danger" onClick={deletePlant}>Delete plant</AppButton>
        </div>
      </div>
    </SlidingPanel>
  );
}
