import { faCheckSquare, faMagnifyingGlass, faTag } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlantSelectorMenu from "./PlantSelectorMenu";
import { useContext, useState } from "react";
import { PlannerContext } from "./PlannerContext";
import classNames from "classnames";
import NewPlantPanel from "../NewPlantPanel";
import { GardenContext } from "pages/garden/GardenContext";
import { faSquare } from "@fortawesome/pro-light-svg-icons";

const PlannerTools = () => {
    const ctx = useContext(GardenContext);
    const plannerCtx = useContext(PlannerContext);
    const {
        currentPlant,
        setCurrentPlant,
        setMode,
        highlightCurrent,
        setHighlightCurrent,
        showLabels,
        setShowLabels } = plannerCtx;
    const [showNewPlantModal, setShowNewPlantModal] = useState(false);

    return <div className="flex w-full flex-row rounded-md pb-2">
        <div className="flex-1 flex flex-row overflow-x-auto">
            <div className="block">
                <PlantSelectorMenu
                    currentPlant={currentPlant}
                    plants={ctx.garden?.plants ?? []}
                    onModeChanged={(m) => setMode?.(m)}
                    onCurrentPlantChanged={(p) => setCurrentPlant?.(p)}
                    showingNewPlantModal={showNewPlantModal}
                    onShowNewPlantModal={(s) => setShowNewPlantModal(s)}
                />
            </div>
            <button
                type="button"
                onClick={() => setHighlightCurrent?.(!highlightCurrent)}
                title="Highlights the currently selected plant in your garden."
                className="min-w-[12rem] w-[12rem] h-[38px] items-center mr-2 flex flex-row border border-main-background-tertiary text-green-800 bg-transparent hover:bg-button-outline-bg rounded-md px-1 pt-1">
                <div className="text-xl px-1">
                    {!highlightCurrent && <FontAwesomeIcon icon={faSquare} />}
                    {highlightCurrent && <FontAwesomeIcon icon={faCheckSquare} />}
                </div>
                <div
                    className={classNames("flex-1 block text-left cursor-pointer select-none transition-colors duration-default text-sm font-semibold disabled:opacity-20 ml-2")}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-1" /> Highlight current
                </div>
            </button>
            <button
                type="button"
                onClick={() => setShowLabels?.(!showLabels)}
                title="Shows labels for planted plants."
                className="min-w-[12rem] w-[12rem] h-[38px] items-center mr-2 flex flex-row border border-main-background-tertiary text-green-800 bg-transparent hover:bg-button-outline-bg rounded-md px-1 pt-1">
                <div className="text-xl px-1">
                    {!showLabels && <FontAwesomeIcon icon={faSquare} />}
                    {showLabels && <FontAwesomeIcon icon={faCheckSquare} />}
                </div>
                <div
                    className={classNames("flex-1 block text-left cursor-pointer select-none transition-colors duration-default text-sm font-semibold disabled:opacity-20 ml-2")}>
                    <FontAwesomeIcon icon={faTag} className="mr-1" /> Show labels
                </div>
            </button>
        </div>
        <NewPlantPanel
            show={showNewPlantModal}
            onClose={() => setShowNewPlantModal(false)}
            garden={ctx.garden!}
            onPlantAdded={(plant) => {
                setCurrentPlant?.(plant);
            }}
        />
    </div>;
}

export default PlannerTools;