import Garden from "data/models/Garden";
import validateV1 from "./v1";
import validateV2 from "./v2";

class Validator {
    public static validateGardenData(data: any): { errors?: string[], garden?: Garden } {
        if (typeof data !== "object") {
            return { errors: ["data is not an object"] };
        }

        if (typeof data.version !== "number") {
            return { errors: ["data.version is not a number"] };
        }

        let result: { errors?: string[], garden?: Garden } = {};
        switch (data.version) {
            case 1:
                result = validateV1(data);
                break;
            case 2:
                result = validateV2(data);
                break;
        }

        if (!result.garden && !result.errors) {
            result.errors = ["Unknown data version of garden."];
        }

        return result;
    }
}

export default Validator;