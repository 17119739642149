import AppButton from "components/common/app_button/AppButton";
import AppCheckbox from "components/common/app_checkbox/AppCheckbox";
import Divider from "components/common/divider/Divider";
import { NotificationContext } from "components/notification_panel/NotificationContext";
import SlidingPanel, {
    PanelAction,
} from "components/sliding_panel/SlidingPanel";
import Transfer from "data/local/Transfer";
import Validator from "data/validation/Validator";
import { db } from "index";
import { GardenContext } from "pages/garden/GardenContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dateToTimestamp, timestampToDateStr, timestampToDateTime } from "utilities/Dates";
import { copyObject } from "utilities/Objects";

type GardenSettingsPanelProps = {
    show: boolean;
    onClose: () => void;
};

export default function GardenSettingsPanel(props: GardenSettingsPanelProps) {
    const ctx = useContext(GardenContext);
    const notifyCtx = useContext(NotificationContext);
    const [rows, setRows] = useState(ctx.garden!.details.rows);
    const [columns, setColumns] = useState(ctx.garden!.details.columns);
    const [name, setName] = useState(ctx.garden!.details.name);
    const [seasonStart, setSeasonStart] = useState<string | null>(ctx.garden!.details.seasonStart);
    const [seasonEnd, setSeasonEnd] = useState<string | null>(ctx.garden!.details.seasonEnd);
    const [invalidDates, setInvalidDates] = useState(false);
    const [warnOnDecrease, setWarnOnDecrease] = useState(false);
    const [deleteChecked, setDeleteChecked] = useState(false);
    const navigate = useNavigate();
    const [invalidSizes, setInvalidSizes] = useState(false);

    useEffect(() => {
        if (rows === undefined || columns === undefined) {
            setInvalidSizes(_ => true);
            return;
        }

        if (isNaN(rows) || isNaN(columns)) {
            setInvalidSizes(_ => true);
            return;
        }

        if (rows < 2 || rows > 20 || columns < 2 || columns > 20) {
            setInvalidSizes(_ => true);
            return;
        }

        setInvalidSizes(_ => false);
    }, [rows, columns]);

    const updateGarden = useCallback(async () => {
        const garden = copyObject(ctx.garden!);
        garden.details.rows = rows;
        garden.details.columns = columns;
        garden.details.name = name;
        garden.details.seasonStart = timestampToDateStr(seasonStart ?? "") ?? "";
        garden.details.seasonEnd = timestampToDateStr(seasonEnd ?? "") ?? "";

        for (let idx = 0; idx < garden.units.length; idx++) {
            const unit = garden.units[idx];
            if (unit.y >= garden.details.rows ||
                unit.x >= garden.details.columns) {
                garden.units.splice(idx, 1);
                idx--;
            }
        }

        await db.saveGarden(garden);
        ctx.onGardenUpdated?.(garden);
        props.onClose();
    }, [props.onClose, rows, columns, name, seasonStart, seasonEnd]);

    useEffect(() => {
        if (props.show) {
            setRows(ctx.garden!.details.rows);
            setColumns(ctx.garden!.details.columns);
            setName(ctx.garden!.details.name);
            setSeasonStart(ctx.garden!.details.seasonStart);
            setSeasonEnd(ctx.garden!.details.seasonEnd);
            setWarnOnDecrease(false);
        }
    }, [props.show]);

    useEffect(() => {
        if (seasonStart !== null && seasonEnd !== null) {
            setInvalidDates(
                timestampToDateTime(seasonStart!) >= timestampToDateTime(seasonEnd!)
            );
        }
        else {
            setInvalidDates(true);
        }
    }, [seasonStart, seasonEnd]);

    useEffect(() => {
        setWarnOnDecrease(rows < ctx.garden!.details.rows || columns < ctx.garden!.details.columns);
    }, [rows, columns]);

    const handleAction = (action: PanelAction) => {
        if (action.id === "cancel") {
            props.onClose();
        } else if (action.id === "save") {
            updateGarden();
        }
    };

    const updateSeasonStart = (date: string) => {
        setSeasonStart(dateToTimestamp(date));
    };

    const updateSeasonEnd = (date: string) => {
        setSeasonEnd(dateToTimestamp(date));
    };

    const deleteGarden = useCallback(async () => {
        await db.deleteGarden(ctx.garden!.id);
        navigate("/gardens");
        notifyCtx.addNotification?.([{
            id: 'deleteGarden' + ctx.garden!.id,
            title: 'Garden deleted',
            message: <span className="block">
                <span className="block">Your garden, {ctx.garden!.details.name}, has been deleted.</span>
            </span>,
            type: 'success'
        }]);
    }, [ctx.garden, notifyCtx.addNotification, notifyCtx.removeNotification, navigate]);

    const copyGarden = useCallback(async () => {
        const newGarden = Validator.validateGardenData(ctx.garden!);
        const gardenName = (newGarden.garden!.details!.name ?? "").trim();
        if (!gardenName.endsWith("(copy)")) {
            newGarden.garden!.details!.name = gardenName + " (copy)";
        }
        db.importGarden(newGarden.garden!);
        notifyCtx.addNotification?.([{
            id: 'copyGarden',
            title: 'Garden copied',
            message: <span className="block">
                <span className="block">Your garden has been copied.</span>
                <AppButton buttonType="outline" className="mt-2" onClick={() => {
                    navigate(`/gardens`);
                }}>Open gardens</AppButton>
            </span>,
            type: 'success',
            closeOnNavigate: true
        }]);
        props.onClose();
    }, [ctx.garden, db, navigate, notifyCtx, notifyCtx.addNotification, notifyCtx.removeNotification, props.onClose]);

    return (
        <SlidingPanel
            show={props.show}
            onClose={props.onClose}
            title="Update your garden"
            subtitle="Change the basic information about your garden."
            actions={[
                { id: "cancel", text: "Cancel" },
                { id: "save", text: "Save", primary: true, disabled: invalidDates || invalidSizes },
            ]}
            onAction={handleAction}
        >
            <div className="px-4 sm:px-6">
                <div className="space-y-6 pt-6">
                    <div>
                        <label
                            htmlFor="garden-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Garden name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="garden-name"
                                id="garden-name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
                <Divider className="my-5" />
                <div>
                    <h1 className="mb-1 font-semibold text-lg">Garden Size</h1>
                    <div>
                        <label
                            htmlFor="rows"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Rows
                            <p className=" text-xxs opacity-50">Min: 2, Max: 20</p>
                        </label>
                        <div className="mt-2">
                            <input
                                name="rows"
                                id="rows"
                                type="number"
                                value={isNaN(rows) ? "" : rows}
                                onChange={(e) => setRows(e.target.valueAsNumber)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <label
                            htmlFor="columns"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Columns
                            <p className=" text-xxs opacity-50">Min: 2, Max: 20</p>
                        </label>
                        <div className="mt-2">
                            <input
                                name="columns"
                                id="columns"
                                type="number"
                                value={isNaN(columns) ? "" : columns}
                                onChange={(e) => setColumns(e.target.valueAsNumber)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    {invalidSizes && (
                        <div className=" rounded-md mt-2 bg-red-100 p-4 text-sm text-red-900">
                            You must have at least 2 and at most 20 rows or columns.
                        </div>
                    )}
                    {!invalidSizes && warnOnDecrease && (
                        <div className=" rounded-md mt-2 bg-red-100 p-4 text-sm text-red-900">
                            These settings will reduce the size of your garden and may cause
                            you to lose configured plants or units.
                        </div>
                    )}
                </div>
                <Divider className="my-5" />
                <div>
                    <h1 className="mb-1 font-semibold text-lg">Growing Season</h1>
                    <div>
                        <label
                            htmlFor="season-start"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Start of growing season
                            <span className="ml-4 block text-xs font-light text-gray-400">
                            </span>
                        </label>
                        <div className="mt-2">
                            <input
                                name="season-start"
                                id="season-start"
                                type="date"
                                value={timestampToDateStr(seasonStart ?? "") ?? ""}
                                onChange={(e) => updateSeasonStart(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <label
                            htmlFor="season-end"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            End of growing season
                            <span className="ml-4 block text-xs font-light text-gray-400">
                            </span>
                        </label>
                        <div className="mt-2">
                            <input
                                name="season-end"
                                id="season-end"
                                type="date"
                                value={timestampToDateStr(seasonEnd ?? "") ?? ""}
                                onChange={(e) => updateSeasonEnd(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    {invalidDates && (
                        <div className=" rounded-md mt-2 bg-red-100 p-4 text-sm text-red-900">
                            The season end date must be after the season start date.
                        </div>
                    )}
                </div>
                <Divider className="my-5" />
                <div>
                    <h1 className="mb-1 font-semibold text-lg">Data Management</h1>
                    <div>
                        <p className="font-semibold mb-1">Copy garden</p>
                        <p className="block text-sm font-medium text-gray-900">
                            Copy your garden so you can use it as a template for a new garden.
                        </p>
                        <AppButton
                            className="mt-2"
                            onClick={copyGarden}>Copy garden</AppButton>
                    </div>
                    <div className="mt-4">
                        <p className="font-semibold mb-1">Export garden</p>
                        <p className="block text-sm font-medium text-gray-900">
                            Export your garden data to a file that can be shared or imported into another garden.
                        </p>
                        <AppButton
                            className="mt-2"
                            onClick={() => Transfer.createGardenDownload(ctx.garden!)}>Export garden</AppButton>
                    </div>
                    <div className="mt-4">
                        <p className="font-semibold mb-1">Delete garden</p>
                        <p className="block text-sm font-medium text-gray-900">
                            Delete your garden to remove all data associated with it.
                        </p>
                        <p className="font-bold mt-1 text-sm text-red-600">This action cannot be undone.</p>
                        <AppCheckbox id="deleteCheck" onChange={e => setDeleteChecked(e.target.checked)} checked={deleteChecked} className="mt-2" label="I want to delete this garden." />
                        {deleteChecked && <AppButton
                            className="mt-2 block"
                            buttonType="danger"
                            onClick={deleteGarden}>Delete garden</AppButton>}
                    </div>
                </div>
            </div>
        </SlidingPanel >
    );
}
