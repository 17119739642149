import GardenPlant from "data/models/GardenPlant";
import { createContext } from "react";
import { EditMode, PaintMode } from "./utilities/Types";

export type PlannerContextType = {
  currentPlant: GardenPlant | undefined | null;
  setCurrentPlant: ((plant: GardenPlant | undefined | null) => void) | undefined;
  painting: boolean;
  setPainting: ((painting: boolean) => void) | undefined;
  paintMode: PaintMode;
  setPaintMode: ((paintMode: PaintMode) => void) | undefined;
  mode: EditMode;
  setMode: ((mode: EditMode) => void) | undefined;
  highlightCurrent: boolean;
  setHighlightCurrent: ((highlight: boolean) => void) | undefined;
  showLabels: boolean;
  setShowLabels: ((showLabels: boolean) => void) | undefined;
};
export const PlannerContext = createContext<PlannerContextType>({
  currentPlant: null,
  setCurrentPlant: undefined,
  painting: false,
  setPainting: undefined,
  paintMode: "placing",
  setPaintMode: undefined,
  mode: "shaping",
  setMode: undefined,
  highlightCurrent: false,
  setHighlightCurrent: undefined,
  showLabels: false,
  setShowLabels: undefined,
});
