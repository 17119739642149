import DialogManager from "./DialogManager";
import WindowVisibilityService from "./WindowVisibilityService";

export default class Services {
  public static WindowVisibility?: WindowVisibilityService;
  public static DialogManager?: DialogManager;

  public static initialize() {
    this.WindowVisibility = new WindowVisibilityService();
    this.WindowVisibility.initialize();
    this.DialogManager = new DialogManager();
  }
}
