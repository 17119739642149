import Title from "components/common/title/Title"
import { AppSupportContext } from "pages/app/AppSupportContext";
import { useContext, useEffect } from "react";

export const HelpPage = () => {
    const ctx = useContext(AppSupportContext);
    
    useEffect(() => {
        ctx.setTitle?.("Help");
    }, []);

    return <div className="flex flex-col h-full w-full">
        <Title className="text-2xl mb-2">Help</Title>
        <div className="p-4 flex-1 rounded-md overflow-hidden bg-white"></div>
    </div>
}