import Garden from "data/models/Garden";
import { createContext } from "react";

export type GardenContextType = {
  title: string | null;
  setTitle: ((title: string) => void) | undefined;
  garden: Garden | undefined;
  setGarden: ((garden: Garden | undefined) => void) | undefined;
  onGardenUpdated: ((garden: Garden) => void) | undefined;
};
export const GardenContext = createContext<GardenContextType>({
  title: "",
  setTitle: undefined,
  garden: undefined,
  setGarden: undefined,
  onGardenUpdated: undefined,
});
