import { Transition } from "@headlessui/react";
import { Fragment, useContext, useMemo } from "react";
import NotifyItem from "./NotifyItem";
import { NotificationContext } from "./NotificationContext";

const NotificationPanel = () => {
    const context = useContext(NotificationContext);
    const show = useMemo(() => {
        return context.notifications.length > 0;
    }, [context.notifications]);

    return <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-30"
    >
        <div className="flex w-full flex-col items-center space-y-4">
            <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="pointer-events-auto w-full max-w-sm space-y-1 ">
                    {show && context.notifications.map((notification) => <NotifyItem key={notification.id} notification={notification} />)}
                </div>
            </Transition>
        </div>
    </div>;
}

export default NotificationPanel;