import { useCallback, useContext, useEffect, useState } from "react";
import { GardenContext } from "./GardenContext";
import { AppContext } from "components/app_root/AppContext";
import Garden from "data/models/Garden";
import { db } from "index";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import GardenMenu from "./GardenMenu";
import AppButton from "components/common/app_button/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

export default function GardenPage() {
    const { id: gardenId } = useParams();
    const { isSmallWindow } = useContext(AppContext);
    const [title, setTitle] = useState<string | null>(null);
    const [garden, setGarden] = useState<Garden | undefined>();
    const [gardenNotFound, setGardenNotFound] = useState<boolean>(false);
    const navigate = useNavigate();

    const getGardens = useCallback(async () => {
        const dbGarden = await db.getGarden(gardenId);
        setGarden(_ => dbGarden);
        setGardenNotFound(_ => !dbGarden);
    }, []);

    const onGardenUpdated = useCallback(async (garden: Garden) => {
        await db.saveGarden(garden);
        setGarden(_ => garden);
    }, []);

    useEffect(() => {
        if (!db.initialized()) {
            return;
        }

        getGardens();
    }, [db.initialized()]);

    return <GardenContext.Provider
        value={{
            title: title,
            setTitle: setTitle,
            garden: garden,
            setGarden: setGarden,
            onGardenUpdated: onGardenUpdated
        }}>
        <div className="relative mx-auto w-full flex flex-1 overflow-hidden">
            {gardenNotFound && <div className="flex-1 flex flex-col justify-center items-center">
                <h1 className="text-3xl text-center font-bold mb-2">Garden not found</h1>
                <div className="text-sm text-center">The garden you are looking for does not exist.</div>
                <AppButton
                    buttonType="primary"
                    onClick={() => navigate('/gardens')}
                    className="my-4"><FontAwesomeIcon icon={faArrowLeft} className="mr-2" />Go back to Gardens list</AppButton>
            </div>
            }
            {!gardenNotFound && garden && <><div className="relative mx-auto w-full flex flex-1 h-dvh overflow-hidden">
                <GardenMenu />
                <div className="min-w-0 flex-1 overflow-auto" style={{ marginTop: isSmallWindow ? '2.5rem' : undefined }}>
                    <div className="p-5 h-full overflow-hidden">
                        <Outlet />
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    </GardenContext.Provider >;
}
