import { useCallback, useContext, useEffect, useState } from "react";
import PlannerTile from "./PlannerTile";
import { getCellDetails, layoutToUnits, unitsToLayout, updatePlantable, updatePlanted } from "./utilities/LayoutUtils";
import { IGardenLayout } from "./utilities/Types";
import Garden from "data/models/Garden";
import { copyObject } from "utilities/Objects";
import { GardenContext } from "pages/garden/GardenContext";
import classNames from "classnames";
import DeferralManager from "utilities/DeferralManager";
import { PlannerContext } from "./PlannerContext";

type PlannerGridProps = {
    rows: number,
    columns: number,
    garden: Garden
};

const PlannerGrid = (props: PlannerGridProps) => {
    const ctx = useContext(GardenContext);
    const plannerCtx = useContext(PlannerContext);
    const { painting, paintMode, currentPlant, mode, highlightCurrent, showLabels } = plannerCtx;
    const { rows, columns } = props;
    const [planted, setPlanted] = useState<IGardenLayout>(unitsToLayout(props.garden.units, props.garden.plants));

    useEffect(() => {
        setPlanted(unitsToLayout(props.garden.units, props.garden.plants));
    }, [props.garden]);

    const updateCellState = useCallback((row: number, col: number, dragging = false) => {
        let updatedLayout: IGardenLayout;

        if (currentPlant === undefined) {
            return;
        }

        if (mode === "shaping") {
            updatedLayout = updatePlantable(row, col, dragging, planted, paintMode);
        } else {
            updatedLayout = updatePlanted(
                row,
                col,
                dragging,
                planted,
                paintMode,
                currentPlant
            );
        }

        setPlanted(updatedLayout);

        DeferralManager.startTimeout("updateGarden", () => {
            const gardenCopy = copyObject(props.garden);
            const units = layoutToUnits(updatedLayout, rows, columns);
            gardenCopy.units = units;
            gardenCopy.details.rows = rows;
            gardenCopy.details.columns = columns;
            ctx.onGardenUpdated?.(gardenCopy);
        }, 200);
    }, [planted, paintMode, currentPlant, rows, columns, mode]);

    const onCellClicked = useCallback((value: {
        row: number;
        column: number;
    }) => {
        updateCellState(value.row, value.column);
    }, [planted, paintMode, currentPlant, rows, columns, mode]);

    const onCellDragged = useCallback((value: {
        row: number;
        column: number;
    }) => {
        updateCellState(value.row, value.column, true);
    }, [planted, paintMode, currentPlant, rows, columns, mode]);

    return <table className="mx-auto pr-4 select-none">
        <tbody>
            <tr>
                <td></td>
                {[...Array(columns).keys()].map((c) => (
                    <td key={c} className="text-center">
                        <span className={classNames("inline-block pb-2 text-gray-300", c === columns - 1 && "pr-4")}>
                            {c + 1}
                        </span>
                    </td>
                ))}
            </tr>
            {[...Array(rows).keys()].map((r) => (
                <tr key={r}>
                    <td>
                        <span className="inline-block pr-2 text-gray-300">
                            {r + 1}
                        </span>
                    </td>
                    {[...Array(columns).keys()].map((c) => {
                        const plantDetails = getCellDetails(r, c, planted);
                        const isHighlighted = !highlightCurrent || (highlightCurrent && currentPlant === undefined) || (highlightCurrent && currentPlant?.id == plantDetails?.planted?.id);

                        return (
                            <td key={`${r}-${c}`} className={c === columns - 1 ? "pr-4" : ""}>
                                <PlannerTile
                                    row={r}
                                    column={c}
                                    painting={painting}
                                    highlighted={isHighlighted}
                                    plantable={!!plantDetails?.plantable}
                                    plant={plantDetails?.planted}
                                    onClick={onCellClicked}
                                    onDrag={onCellDragged}
                                    mode={mode}
                                    showLabel={showLabels}
                                />
                            </td>
                        );
                    })}
                </tr>
            ))}
        </tbody>
    </table>
}

export default PlannerGrid;