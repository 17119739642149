import { createContext } from "react";
import { NotificationData } from "./NotificationData";

export type NotificationContextType = {
    notifications: NotificationData[];
    addNotification: null | ((notifications: NotificationData[]) => void);
    removeNotification: null | ((id: string) => void);
};

export const NotificationContext = createContext<NotificationContextType>({
    notifications: [],
    addNotification: null,
    removeNotification: null,
});
