import { Outlet } from "react-router-dom";
import AppRoot from "../app_root/AppRoot";
import { NotificationContext } from "components/notification_panel/NotificationContext";
import { NotificationData } from "components/notification_panel/NotificationData";
import { useState, useCallback } from "react";
import NotificationPanel from "components/notification_panel/NotificationPanel";
import LaunchModal from "components/launch_modal/LaunchModal";

export default function Root(props: React.PropsWithChildren) {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const onAddNotification = useCallback((newNotifications: NotificationData[]) => {
    const notificationsToAdd = newNotifications.filter(n => !notifications.find(n2 => n2.id === n.id));
    if (notifications.length > 0 && notificationsToAdd.length === 0) {
      return;
    }

    setNotifications(_ => [...notifications, ...notificationsToAdd]);
  }, [notifications]);

  const onRemoveNotification = useCallback((id: string) => {
    if (notifications.length === 0 || !notifications.find(n => n.id === id)) {
      return;
    }

    const notification = notifications.find(n => n.id === id);
    setNotifications(_ => notifications.filter(n => n.id !== id));
    setTimeout(() => notification?.onClosed?.(), 0);
  }, [notifications]);

  return (<NotificationContext.Provider value={{
    notifications: notifications,
    addNotification: onAddNotification,
    removeNotification: onRemoveNotification
  }}>
    <div className="h-dvh max-h-dvh">
      <AppRoot>{props.children ? props.children : <Outlet />}</AppRoot>
    </div>
    <LaunchModal />
    <NotificationPanel />
  </NotificationContext.Provider>
  );
}
