import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition, Dialog } from "@headlessui/react";
import classNames from "classnames";
import AppButton from "components/common/app_button/AppButton";
import SolidLogo from "components/common/logo/SolidLogo";
import { GardenContext } from "pages/garden/GardenContext";
import { Fragment, useContext } from "react";
import { range } from "utilities/Numbers";

type PlantMapModalProps = {
  show: boolean;
  onClose: () => void;
  showPlantId?: string;
};

const PlantMapModel = (props: PlantMapModalProps) => {
  const { show, onClose, showPlantId } = props;
  const ctx = useContext(GardenContext);
  const { rows, columns } = ctx.garden!.details;

  const plantUnits = ctx.garden!.units!;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-white rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="pb-1">
                    {range(1, rows).map((row) => {
                      return (
                        <div key={row} className="flex flex-row space-x-1 pb-1">
                          {range(1, columns).map((column) => {
                            const unit = plantUnits.find(u => u.x + 1 === column && u.y + 1 === row);

                            return (
                              <div
                                key={column}
                                className={classNames(
                                  " aspect-square rounded-md",
                                  unit?.plantId === showPlantId 
                                    ? "bg-button-primary-bg"
                                    : unit ? "bg-main-background-secondary" : "bg-white"
                                )}
                                style={{ width: "10%" }}
                              ></div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <AppButton
                    buttonType="outline"
                    className="w-full flex flex-row items-center justify-center"
                    onClick={onClose}
                  >
                    <span className="mr-2">Close Map</span>
                  </AppButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PlantMapModel;
