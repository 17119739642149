export default class DeferralManager {
    private static timeoutHandles: { [key: string]: number | undefined } = {};

    public static startTimeout(id: string, callback: () => void, timeoutMs: number): number {
        DeferralManager.stopTimeout(id);

        const handle = window.setTimeout(() => {
            callback();
            DeferralManager.stopTimeout(id);
        }, timeoutMs);

        DeferralManager.timeoutHandles[id] = handle;
        return handle;
    }

    public static stopTimeout(id: string): void {
        window.clearTimeout(DeferralManager.timeoutHandles[id]);
        delete DeferralManager.timeoutHandles[id];
    }

    public static stopTimeoutCleanup(id: string): () => void {
        return () => {
            DeferralManager.stopTimeout(id);
        };
    }
}
