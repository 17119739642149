export default class WindowVisibilityService {
    private _windowFocused: boolean | null = null;

    initialize(): void {
        document.addEventListener('visibilitychange', () => this.onVisibilityChanged());
        window.onblur = () => this.onVisibilityChanged(false);
        window.onfocus = () => this.onVisibilityChanged(true);
        this.onVisibilityChanged();
    }

    private onVisibilityChanged(windowFocused: boolean | null = null): void {
        if (this.windowCurrentlyFocused(windowFocused)) {
            this._windowFocused = true;
        } else if (this.windowNotCurrentlyFocused(windowFocused)) {
            this._windowFocused = false;
        }
    }

    private windowCurrentlyFocused(windowFocused: boolean | null = null): boolean {
        return (
            (this._windowFocused == null || this._windowFocused === false) &&
            ((document.visibilityState === 'visible' && windowFocused == null) || windowFocused === true));
    }

    private windowNotCurrentlyFocused(windowFocused: boolean | null = null): boolean {
        return (
            (this._windowFocused == null || this._windowFocused === true) &&
            (document.visibilityState === 'hidden' || windowFocused === false));
    }

    public focused(): boolean {
        return !!this._windowFocused;
    }
}
