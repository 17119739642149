export const range = (start: number, end: number): number[] => {
    if (end < start) {
        throw new Error("Start must be less than or equal to end");
    }

    return [...Array(end - start + 1).keys()].map(n => start + n);
}

export const parseNumber = (value: string | null | undefined, defaultValue: number, min?: number, max?: number): number => {
    const parsed = parseInt(value ?? `${defaultValue}`);
    let number = isNaN(parsed) ? defaultValue : parsed;

    if (min) {
        number = Math.max(number, min);
    }

    if (max) {
        number = Math.min(number, max);
    }

    return number;
}

export const defaultIfNaN = (value: number | null | undefined, defaultValue: number): number => {
    return isNaN(value ?? NaN) ? defaultValue : value!;
}