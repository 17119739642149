import { useEffect, useState } from "react";
import "./animations.css";
import { usePrevious } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMound, faSeedling } from "@fortawesome/pro-solid-svg-icons";
import GardenPlant from "data/models/GardenPlant";
import { EditMode } from "./utilities/Types";

type PlannerTileProps = {
  row: number;
  column: number;
  plantable: boolean;
  highlighted: boolean;
  plant: GardenPlant | null | undefined;
  painting: boolean;
  onClick?: (values: { row: number; column: number }) => void;
  onDrag?: (values: { row: number; column: number }) => void;
  mode: EditMode;
  showLabel: boolean;
};

export default function PlannerTile(props: PlannerTileProps) {
  const { row, column, plant, plantable } = props;
  const [animatedClass, setAnimatedClass] = useState<string | null>(null);
  const previousPlantable = usePrevious(plantable);
  const previousPlant = usePrevious(plant);
  const previousAnimated = usePrevious(animatedClass);

  useEffect(() => {
    let change: "pulse" | "shrink" | null = null;

    if (previousPlant?.id != plant?.id) {
      if (plant) {
        change = "pulse";
      } else {
        change = "shrink";
      }
    }

    if (previousPlantable != plantable) {
      if (plantable) {
        change = "pulse";
      } else {
        change = "shrink";
      }
    }

    if (!change) {
      return;
    }

    let newAnimClass = "";

    if (previousAnimated) {
      if (previousAnimated.includes(`${change}-1`)) {
        newAnimClass = `${change}-2`;
      } else {
        newAnimClass = `${change}-1`;
      }
    } else {
      newAnimClass = `${change}-1`;
    }

    setAnimatedClass(newAnimClass);
  }, [plantable, plant]);

  let classes =
    "relative m-1 h-[64px] w-[64px] min-w-[64px] cursor-pointer text-center rounded-md transition-opacity align-middle leading-[64px]";
  let color = "#e5e7eb";
  if (plant) {
    color = plant.color;
  } else if (plantable) {
    color = "#b8a382";
  }

  if (!props.highlighted) {
    classes += " opacity-20";
  }

  classes += ` ${animatedClass || ""}`;

  return (
    <div className="group relative text-white">
      <button
        title={`Row ${row + 1}, Column ${column + 1}, ${plant ? plant.name : plantable ? "Plantable" : "Not Plantable"
          }`}
        draggable={props.painting}
        onClick={() => {
          if (!props.painting) {

            props.onClick && props.onClick({ row, column });
          }
        }}
        onDragStart={(e) => {
          e.dataTransfer.setDragImage(new Image(), 0, 0);
        }}
        onDragEnter={() => {
          if (props.painting) {
            props.onDrag && props.onDrag({ row, column });
          }
        }}
        className={classes}
        style={{ backgroundColor: color }}
      >
        {plant && (
          <FontAwesomeIcon
            icon={faSeedling}
            className="h-8 w-8 align-middle leading-[64px] opacity-10"
          />
        )}
        {plantable && !plant && (
          <FontAwesomeIcon
            icon={faMound}
            className="h-8 w-8 align-middle leading-[64px] opacity-10"
          />
        )}
        {props.showLabel && plant && <div className="absolute bottom-0 w-full max-h-full bg-black bg-opacity-30 py-2 px-1 text-center text-xxs font-bold rounded-md"><div className="line-clamp-3 break-words">{plant.name}</div></div>}
      </button>
    </div>
  );
}
