import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faHandHoldingHeart } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useCallback } from "react";

const SharingButtons = () => {
    const canShare = useMemo(() => {
        return !!navigator['canShare'];
    }, []);

    const share = useCallback(async () => {
        const shareData = {
            title: "Squardener",
            text: "Plan your next garden!",
            url: "https://www.squardener.com",
        };

        const btn = document.querySelector("button");
        const resultPara = document.querySelector(".result");

        try {
            await navigator.share(shareData);
        } catch (err) {
            console.error(err);
        }
    }, []);

    return <div className="flex flex-row items-center relative pt-4 h-10 leading-none space-x-1">
        {/* <a href="https://www.instagram.com/squardener/" target="_blank" className="h-6 w-6 text-[1.5rem] hover:fill-button-primary-bg hover:text-button-primary-bg"><FontAwesomeIcon icon={faInstagram} /></a> */}
        <a className="text-xs flex-1 text-center leading-6 font-bold px-2 rounded-md bg-button-primary-bg hover:bg-button-primary-bg-hover text-button-fg h-6 inline-block" href="https://www.buymeacoffee.com/squardener" target="_blank"><h1><FontAwesomeIcon icon={faHandHoldingHeart} /> Help grow Squardener!</h1></a>
    </div>;
}

export default SharingButtons;