export default class DialogManager {
    private _currentDialogIndex: number = 40;

    public getNextDialogIndex(): number {
        const newIndex = this._currentDialogIndex += 5;
        return newIndex;
    }

    public dialogClosed(): void {
        this._currentDialogIndex = Math.max(40, this._currentDialogIndex -= 5);
    }
}
