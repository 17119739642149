import { Fragment, useCallback, useMemo, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AppButton from "components/common/app_button/AppButton";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-light-svg-icons";

export type PanelAction = {
  id: string;
  text: string;
  primary?: boolean;
  disabled?: boolean;
};

type SlidingPanelProps = {
  show: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  actions?: PanelAction[];
  error?: string | undefined | null;
  onAction?: (action: PanelAction) => void;
  overlay?: boolean | undefined;
  direction?: 'left' | 'right';
  disableCloseOnMouseOutClick?: boolean;
} & React.PropsWithChildren;

export default function SlidingPanel(props: SlidingPanelProps) {
  const closeRef = useRef<HTMLButtonElement>(null);
  const withOverlay = props.overlay === undefined ? true : props.overlay;
  const direction = props.direction ?? 'right';

  const disableOnMouseOut = props.disableCloseOnMouseOutClick === undefined ? true : props.disableCloseOnMouseOutClick;

  const zIndex = useMemo(() => {
    // if (props.show) {
    //   return Services.DialogManager?.getNextDialogIndex() ?? 40;
    // } else {
    //   Services.DialogManager?.closeDialog();
    //   return 40;
    // }

    return 40;
  }, [props.show]);

  const eatClick = useCallback(() => { }, []);

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative" style={{ zIndex }} static onClose={disableOnMouseOut ? eatClick : props.onClose} initialFocus={closeRef}>
        {withOverlay && (
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0  bg-gray-900 bg-opacity-60 transition-opacity" />
          </Transition.Child>
        )}
        {!withOverlay && <div className="fixed inset-0" />}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className={classNames("pointer-events-none fixed inset-y-0 flex max-w-full", direction === "right" ? "pl-10 sm:pl-16 right-0" : "pr-10 sm:pr-16 left-0")}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-200"
                enterFrom={direction === "right" ? "translate-x-full opacity-0" : "-translate-x-full opacity-0"}
                enterTo="translate-x-0 opacity-100"
                leave="transform transition ease-in-out duration-200 sm:duration-200"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo={direction === "right" ? "translate-x-full opacity-0" : "-translate-x-full opacity-0"}
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md p-4">
                  <form className="flex h-full flex-col overflow-hidden rounded-lg  bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto flex flex-col">
                      {props.title && props.subtitle && <div className="bg-main-background-tertiary px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          {props.title && <Dialog.Title className="text-xl font-semibold leading-6 text-alternate">
                            {props.title}
                          </Dialog.Title>}
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              ref={closeRef}
                              type="button"
                              className="bg-transparent text-alternate focus:outline-none focus:ring-2 focus:ring-primary"
                              onClick={props.onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <FontAwesomeIcon icon={faClose} />
                            </button>
                          </div>
                        </div>
                        {props.subtitle && (
                          <div className="mt-1">
                            <p className="text-xs text-alternate opacity-60">
                              {props.subtitle}
                            </p>
                          </div>
                        )}
                      </div>}
                      <div className="flex flex-1 flex-col justify-between pb-6 overflow-auto">
                        {props.children}
                      </div>
                    </div>
                    {(props.actions || props.error) && (
                      <div className="bg-main-background-secondary p-4">
                        {props.error && <div className="text-sm bg-red-500 p-3 rounded-md mb-2 text-bright font-bold">{props.error}</div>}
                        <div className="flex flex-shrink-0 justify-end">
                          {props.actions?.map((a) => {
                            return (
                              <AppButton
                                key={a.id}
                                type="button"
                                className="ml-4"
                                buttonType={a.primary ? "primary" : "outline"}
                                disabled={a.disabled}
                                onClick={() =>
                                  props.onAction &&
                                  !a.disabled &&
                                  props.onAction(a)
                                }
                              >
                                {a.text}
                              </AppButton>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
